import React, { useState } from 'react';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import Input from '../Inputs/Input';
import FileUploader2 from '../Helpers/FileUploader2';
import {useReactToPrint} from 'react-to-print';
import { DebitOrderForm } from './DebitOrderForm';
import { useMutation, useQuery } from 'react-query';
import Auth from '../../API/Auth';
import AccountsService from '../../API/AccountsService';

const InsuranceDebitOrder = (props) => {

    const [state, setState] = useState({ ...props });

    const componentRef = React.useRef(null);

    const handleClick = useReactToPrint({
        content:() => componentRef.current
    });

    useQuery("user-detail",Auth.Me,{
        onSuccess:(x)=>{
            const addre = x.data.user?.address;
            const addressValue = addre?.address ||" " + " " + addre?.appartmentSuite||" " + " " + addre?.city||" " +" " +  addre?.country||" " + " "  + addre?.state||" ";
            const debitOrderId = x.data.user?.userAccountNumber?.slice(0, 9) + props._id.substring(props?._id?.length - 4)
            setState({...state,debitOrder:debitOrderId,...x.data.user,address:addressValue ||' ',userID:x.data.user?._id});
        }
    });

    const {mutate} = useMutation(AccountsService.createNewSub,{
        onSuccess:()=>{
            props.next();
        },
        onSettled:()=>{
            setState({...state,loading:false,error:null});
        } ,
        onError:(x)=>{
            setState({...state,error:x.message})
        } 
    });

    const handleActivationClick =()=>{

        setState({...state,loading:true});

        const newBen = (props.beneficiaries||[]).map((x)=>{
            return {
                account:state.userID,
                entity:props._id,
                beneficiary:x._id
            }
        });

        const newSubscription = {
            user:state.userID,
            subscriptionType:"insurance-sub",
            subscriptionReference:state.debitOrder,
            subscriptionMethod:"debit-order",
            bankAccountName:state.accountName,
            subscriptionFirstPaymentDate:state.firstDate,
            subscriptionDate:state.debitOrderDate,
            subscriptionContactPhone:state.phone,
            subscriptionAddress:state.address,
            subscriptionBankName:state.bankName,
            subscriptionTotalAmount:props?.recurring,
            subscriptionBankBranchNumber:state.branchNumber,
            subscriptionBankBranchName:state.branchName,
            subscriptionBankAccountNumber:state.accountNumber,
            subscriptionBankType:state.typeOfAccount,
            subscriptionInsuranceProduct:props?._id,
            beneficiaries:JSON.stringify(newBen)
         };

        var formData = new FormData();
        
        (state.files || []).map((file) => {
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in newSubscription){
        formData.append(key,newSubscription[key]);
        };

        mutate(formData);
    };



    return (
        <Row>
            <Col lg="1">
                <Button onClick={props.prev}>
                    Back
                </Button>
            </Col>
            <Col lg="4">
                <h3 style={{ fontWeight: 600 }}>Debit Order Mandate Details</h3>
                <p style={{ marginBottom: 10 }}>
                    Please verify the following details as per your user account and complete all fields, we will require you to insert
                    your electronic signature, and these details will be used to debit your bank account to pay for your cover.
                </p>
                {
                    state.error && <Alert>
                        {state.error}
                    </Alert>
                }
                {
                    state.loading ? <Spinner /> :
                        <div style={{ marginLeft: -10 }}>
                            <Input
                                label="First Debit Order Date"
                                type="date"
                                edit
                                onChange={(e) => {
                                    setState({ ...state, firstDate: e.target.value })
                                }}
                            />
                            <Input
                                label="Subscription Order Date (of each month)"
                                edit
                                type="select"
                                onChange={(e) => {
                                    setState({ ...state, debitOrderDate: e.target.value })
                                }}
                            >
                                <option value="">Select Subscription Date</option>
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="5">5th</option>
                                <option value="7">7th</option>
                                <option value="15">15th</option>
                                <option value="25">25th</option>
                                <option value="26">26th</option>
                                <option value="30">30th</option>
                                <option value="31">31th</option>
                            </Input>
                            <Input
                                type='text'
                                edit
                                label="Account Holder Name"
                                defaultValue={state?.accountName}
                                onChange={(x) => {
                                    setState({ ...state, accountName: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Contact Number"
                                defaultValue={state.phone}
                                onChange={(x) => {
                                    setState({ ...state, phone: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Address"
                                defaultValue={state.address === undefined ? " " : state.address}
                                onChange={(x) => {
                                    setState({ ...state, address: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Bank Name"
                                defaultValue={state?.bankName}
                                onChange={(x) => {
                                    setState({ ...state, bankName: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Branch Name and Town"
                                defaultValue={state?.branchName}
                                onChange={(x) => {
                                    setState({ ...state, branchName: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Branch Number"
                                name="branchNumber"
                                onChange={(x) => {
                                    setState({ ...state, branchNumber: x.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                edit
                                label="Account Number"
                                defaultValue={state?.accountNumber}
                                onChange={(x) => {
                                    setState({ ...state, accountNumber: x.target.value });
                                }}
                            />
                            <Input
                                type="select"
                                label="Type Of Account"
                                edit
                                onChange={(x) => {
                                    setState({ ...state, typeOfAccount: x.target.value });
                                }}
                            >
                                <option value="">Select account type</option>
                                <option value="savings">Savings</option>
                                <option value="current">Current (cheque)</option>
                            </Input>
                            <FileUploader2
                            title="upload your signed copy here"
                            onFileUpload={(x)=>setState({...state,files:x})}
                            />
                            <Button onClick={handleActivationClick} >Complete Debit Order Setup</Button>
                        </div>
                }
            </Col>
            <Col lg="7">
               <div style={{paddingLeft:50,paddingRight:50}} ref={componentRef} dangerouslySetInnerHTML={{ __html: DebitOrderForm({...state}) }}></div> 
               <Button 
               onClick={handleClick}
                className='btm-sm' style={{marginLeft:50,marginTop:20,width:150}}>
                    Download Copy
                </Button>
            </Col>
        </Row>
    )
}

export default InsuranceDebitOrder;