import React, { useEffect, useState } from 'react'
import { Card, Modal, ModalBody, Row, Spinner, Col, Button, Offcanvas } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query';
import AccountsService from '../../API/AccountsService';
import Auth from '../../API/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faDeleteLeft, faDotCircle, faRefresh, faSquarePen, faUserFriends, faWarning } from '@fortawesome/free-solid-svg-icons';
import InsuranceCanvas from './InsuranceCanvas';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const InsuranceModal = (props) => {

    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({ showCover: false });
    const [beneficiaries, setBeneficiaries] = React.useState([]);


    useQuery("insurance-products", AccountsService.getInsuranceProducts, {
        onSettled: () => {
            setLoading(false);
        },
        onSuccess: (x) => {
            const insuranceAccount = x.data.insuranceAccount;
            const insuranceProducts = x.data.insuranceProducts;
            const subscription = (x.data.subscription||[]).map((x)=>{
                return x.subscriptionInsuranceProduct;
            });
            const membershipsEntities = (x.data.memberships || []).map((x) => {
                return (x.subscriptionMembershipProduct?.buskets||[]).map((x) => (x.membershipEntities||{}).map(x => x.id))
            }
            );
            const ems = membershipsEntities[0]||[];

            const emv2 = ems[0]||[];

            setState({ insuranceAccount, insuranceProducts, subscription:[...emv2,...subscription], membershipsEntities });
        },
        onError: (x) => {
            console.log(x);
        }
    })

    const { refetch } = useQuery("beneficiaries", () => Auth.getBeneficiariesDetails(), {
        onSuccess: (x) => {
            setBeneficiaries(x.data.beneficiaries)
        },
        onSettled: () => {
        }
    });

    // const activatePlan = useMutation(AccountsService)

    return (
        <Modal
            show={props.show}
            style={{ zIndex: 10000 }}
            onHide={props.onClose}
            size="xl"
        >
            <Modal.Header
                closeButton
                style={{ padding: 20, backgroundColor: '#334155', color: '#fff', borderRadius: 0 }}
            >Tsigiro Usekelo Funeral Insurance</Modal.Header>
            <ModalBody>
                {
                    loading ? <Spinner /> :
                        (state.subscription || []).length <= 0 && (state.membershipsEntities||[]).length <= 0 ?
                            <Row>
                                {
                                    !state.showCover && state.insuranceProducts.map((x) => {
                                        return <Col lg="6" className='insurance-products'>
                                            <h3>{x.entityName}</h3>
                                            <h5>ZAR {x.expenseAmount.toFixed(2)}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: x.description }}></div>
                                            <Button
                                                onClick={() => {
                                                    setState({ ...state, selectedCover: x, showCover: true });
                                                }}
                                                style={{ position: 'absolute', top: 0, right: 20, width: '20%' }}
                                            >Activate</Button>
                                        </Col>
                                    })
                                }
                            </Row> :
                            <Row>
                                <p>Insurance Account Active, you can visit invest.tsigiro.com to check past transactions</p>
                                {
                                    !state.showCover && state.insuranceProducts.map((x) => {
                                        return <Col lg="6" className='insurance-products' style={{
                                            backgroundColor:state.subscription.includes(x._id) ? '#f4f6fb' : '#fff'
                                        }}>
                                            <h3>{x.entityName}</h3>
                                            <h5>ZAR {x.expenseAmount.toFixed(2)}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: x.description }}></div>
                                            {state.subscription.includes(x._id) &&
                                                // <Button
                                                //     disabled
                                                //     onClick={() => {
                                                //         setState({ ...state, selectedCover: x, showCover: true });
                                                //     }}
                                                //     style={{ position: 'absolute', top: 20, right: 20, width: '20%' }}
                                                // >Active</Button>
                                                <FontAwesomeIcon style={{top:5,right:5,position:'absolute'}} icon={faDotCircle} color='green'/>
                                                }
                                        </Col>
                                    })
                                }
                            </Row>
                }
                {
                    state.showCover && <Row>
                        <Col lg="6">
                            <Button onClick={() => {
                                setState({ ...state, showCover: false });
                            }} style={{ width: 150, }}>Back</Button>
                            <h3>{state.selectedCover.entityName}</h3>
                            <div dangerouslySetInnerHTML={{ __html: state.selectedCover.description }}></div>
                        </Col>
                        <Col lg="6">
                            <Row>
                                <Col>
                                    <h3>Configure Beneficiaries</h3>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        size="1x"
                                        onClick={refetch}
                                        style={{ color: 'green', margin: 20, cursor: 'pointer' }}
                                    />
                                </Col>
                            </Row>
                            {
                                (beneficiaries || []).map((x, index) => {
                                    return <Row style={{ backgroundColor: '#f4f6fb', margin: 10, padding: 20 }}>
                                        <Col lg="10">
                                            {x?.firstName}
                                        </Col>
                                        <Col lg="2">
                                            <FontAwesomeIcon
                                                icon={faDeleteLeft}
                                                size="2x"
                                                onClick={() => {
                                                    const newBeneficiaries = [...beneficiaries];
                                                    newBeneficiaries.splice(index, 1);
                                                    setBeneficiaries(newBeneficiaries);
                                                }}
                                                style={{ color: '#eb5e34', marginRight: 10, cursor: 'pointer' }} />
                                        </Col>
                                    </Row>

                                })
                            }
                            <Link to="/beneficiaries">
                                <FontAwesomeIcon
                                    icon={faAdd}
                                    size="2x"
                                    onClick={props.onClose}
                                    style={{ color: 'green', margin: 20, cursor: 'pointer' }}
                                />
                            </Link>

                        </Col>
                        <Button
                            onClick={() => setState({ ...state, showCanvas: true })}
                        >Activate cover for ZAR {state?.selectedCover?.expenseAmount.toFixed(2)}p/m</Button>
                    </Row>
                }
                <InsuranceCanvas
                    show={state.showCanvas}
                    beneficiaries={beneficiaries}
                    {...state?.selectedCover}
                    onClose={() => setState({ ...state, showCanvas: false })}
                />
            </ModalBody>
        </Modal>
    )
}

export default InsuranceModal