import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Input from '../Inputs/Input';
import { useMutation, useQuery } from 'react-query';
import AccountsService from '../../API/AccountsService';
import Auth from '../../API/Auth';

const InsuranceVisa = (props) => {

 const [state,setState] = useState({loading:true,...props});

 const minDate =()=>{
    const date = new Date();
    const month = (date.getMonth() + 1 ) < 10 ? '0' +(date.getMonth() + 1 ) : (date.getMonth() + 1 );
    const min = date.getFullYear() + "-" + month + "-" + (date.getDate()+3);
    return min;
};

const minDateValue = minDate();

useQuery("user-detail",Auth.Me,{
    onSuccess:(x)=>{
        const addre = x.data.user?.address;
        const addressValue = addre?.address ||" " + " " + addre?.appartmentSuite||" " + " " + addre?.city||" " +" " +  addre?.country||" " + " "  + addre?.state||" ";
        const debitOrderId = x.data.user?.userAccountNumber?.slice(0, 9) + props._id.substring(props?._id?.length - 4)
        setState({...state,debitOrder:debitOrderId,...x.data.user,address:addressValue ||' ',userID:x.data.user?._id});
    }
});


const {mutate} = useMutation(AccountsService.createTempSubs,{
    onSuccess:()=>{
        document.manageSub.method = "POST";
        document.manageSub.action ='https://cde.netcash.co.za/Site/TokeniseCardExternal.aspx';
        document.manageSub.submit();
    },
    onSettled:()=>{
        setState({...state,loading:false,error:null});
    } ,
    onError:(x)=>{
        setState({...state,error:x.message})
    } 
});


const handleSubmit =(e)=>{

    e.preventDefault();

    setState({...state,loadingForm:true,error:null});

    const newBen = (props.beneficiaries||[]).map((x)=>{
        return {
            account:state.userID,
            entity:props._id,
            beneficiary:x._id
        }
    });

    const newSubscription = {
        user:state.userID,
        subscriptionType:"insurance-sub",
        subscriptionReference:state.debitOrder,
        subscriptionMethod:"credit-card",
        subscriptionFirstPaymentDate:state.subscriptionFirstPaymentDate,
        subscriptionDate:state.subscriptionDate,
        bankAccountName:state.bankAccountName,
        subscriptionContactPhone:state.subscriptionContactPhone,
        subscriptionAddress:state.subscriptionAddress,
        subscriptionInsuranceProduct:props?._id,
        subscriptionTotalAmount:props.recurring,
        creditCardType:state.creditCardType,
        beneficiaries:newBen
     };

     mutate(newSubscription);
};



  return (
    <Row>
        <Col lg="3">
            <Button style={{width:120}} onClick={props.prev}>
                Back
            </Button>
        </Col>
        <Col lg="6">
        <form
        name='manageSub'
        onSubmit={handleSubmit}
        >
         <Input id="PciKey" name="PciKey" edit type="hidden" value="a1fd3a49-4666-4ef9-86aa-d217990e2247" />
         <Input type="hidden" name="Extra1" edit value={state.debitOrder} />
         <Input
            edit
            type='date'
            min={minDateValue}
            name="subscriptionFirstPaymentDate"
            label="First Subscription Date"
            max={31}
            onChange={(x)=>{
                setState({...state,subscriptionFirstPaymentDate:x.target.value});
            }}
            />
            <Input
            type='select'
            label="Subscription Order Date (of each month)"
            max={31}
            edit
            name="subscriptionDate"
            min={0}
            onChange={(x)=>{

                setState({...state,subscriptionDate:x.target.value});
            }}
            >
                <option value="">Select Subscription Date</option>
                <option value="1">1st</option>
                <option value="2">2nd</option>
                <option value="5">5th</option>
                <option value="7">7th</option>
                <option value="15">15th</option>
                <option value="25">25th</option>
                <option value="26">26th</option>
                <option value="30">30th</option>
                <option value="31">31th</option>
            </Input>
            <Input
            type='text'
            edit
            label="Credit Card holder name"
            name="bankAccountName"
            onChange={(x)=>{
                setState({...state,bankAccountName:x.target.value});
            }}
            />
            <Input
            type="select"
            edit
            label="Credit Card Type"
            name="creditCardType"
            onChange={(x)=>{
                setState({...state,creditCardType:x.target.value});
            }}
            >
                <option value="">Select account type</option>
                <option value="savings">Savings</option>
                <option value="current">Current (cheque)</option>
            </Input>
            <Input
            type='text'
            edit
            label="Contact Number"
            name='subscriptionContactPhone'
            onChange={(x)=>{
                setState({...state,subscriptionContactPhone:x.target.value})
            }}
            />
            <Input
            type='text'
            edit
            containerClass='mb-3'
            label="Address"
            name="subscriptionAddress"
            onChange={(x)=>{
                 setState({...state,subscriptionAddress:x.target.value})
            }}
            />
              <Button style={{marginTop:10,marginLeft:10}} disabled={state.loadingForm} type="submit" >
                    {
                        state.loadingForm ? <Spinner></Spinner> : "Activate Cover"
                    }
              </Button>
         </form>
        </Col>
        <Col lg="3">
        </Col>
    </Row>
  )
}

export default InsuranceVisa