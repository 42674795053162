import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React ,{useState} from 'react';
import logo from '../../assets/logo.png'
import Fulllogo from '../../assets/Fulllogo.svg';
import Personal from './SettingComponents/Personal';
 import { faGrip, faBars, faRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import SecondaryAccounts from './SettingComponents/SecondaryAccounts';
import BillingAndBanking from './SettingComponents/BillingAndBanking';
import Notifications from './SettingComponents/Notifications';
import Beneficiaries from './SettingComponents/Beneficiaries';
import PrivacyAndData from './SettingComponents/PrivacyAndData';
import {Link} from 'react-router-dom';
import {setLoggedInUser} from '../../API/Cookies';
import {SelectorConstants} from './SelectorConstants';
import { usePopper } from 'react-popper';
import Navbar from '../NavBar/NavBar';
const Home = () => {

  const [selected, setSelected] = React.useState(0);

  return (
    <>
      <Navbar/>
      <div className='container' style={{backgroundColor:'#fff',height:'100%',marginTop:40}}>
          {/* <div className='profile-complete'>
              <p>Update all personal details to start building your investments portfolio</p>
          </div> */}
          {/* <div className='header-home-logo'>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <img 
            src={Fulllogo} 
            style={{width:180}}
            />
            </div>

            <div className='app-selection-desktop'>
                <div className="ltn__drop-menu user-menu">
                      <ul>
                        <li>
                          <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}} >
                              <FontAwesomeIcon icon={faGrip} size="1x" style={{cursor:'pointer',color:"#5f6368",fontSize:28,padding:10}} />
                          </div>
                          <ul className="go-top">
                              {
                                  SelectorConstants.map((x)=>{
                                  return <li   
                                  onClick={()=>x.redirectURL()}
                                  style={{color:'#000'}}>
                                              <img src={x.image} style={{width:30,height:30,marginRight:10}}/>
                                              <p className='hvr-link' style={{margin:0}}>{x.title}<br></br><span>{x.description}</span></p> 
                                        </li>
                                  })
                              }
                          </ul>
                        </li>
                      </ul>
                    </div>
                <FontAwesomeIcon
                onClick={() => {
                  setLoggedInUser();
                  window.location.href = "/"
                }}
                icon={faRightFromBracket} style={{cursor:'pointer',color:"#626363",fontSize:22,marginLeft:20,marginTop:10,padding:10}}   />
            </div>

            <div className='app-selection-mobile'>
                  <FontAwesomeIcon icon={faBars} size="1x" style={{cursor:'pointer'}} onClick={openNav}/>
            </div>

          </div> */}
        
        <div className='selector-container'>
          <div id="myNav" className='header-menus'>
            <div className='header-menus'>
                {/* {
                  menus.map((menu, index) => {
                    return (
                      <div className='single-selection ' onClick={() =>setSelected(index)}>
                        <h5>{menu.name}</h5>
                        {selected === index && <hr style={{height:'2px',marginBottom:-2,backgroundColor:'#000' }}></hr>}
                      </div>
                    )
                  })
                } */}
            </div>
          </div>
          {/* <hr style={{marginTop:0,height:'2px',backgroundColor:'#eee',border:'none'}}></hr> */}
          
        </div>
      </div>
    </>
  )
}

export default Home