import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../src/components/styles/auth.css';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

// );
const queryClient = new QueryClient()
function Main() {
    return (
        // Provide the client to your App
        <QueryClientProvider client={queryClient}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </QueryClientProvider>
    )
}


const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
