import React, { useState } from 'react'
import { Button, Col, Offcanvas, ProgressBar, Row, Table } from 'react-bootstrap'
import Input from '../Inputs/Input';
import { Step, Steps, Wizard } from 'react-albus';
import InsuranceDebitOrder from './InsuranceDebitOrder';
import InsuranceVisa from './InsuranceVisa';
import CompleteDO from './SetupComplete';

const InsuranceCanvas = (props) => {

  const [method, setMethod] = useState('');

  return (
    <Offcanvas style={{ width: '100%', zIndex: 10001 }} show={props.show} onHide={props.onClose} placement="end" >
      <Offcanvas.Header closeButton><h3>{props.entityName}(ZAR {props.expenseAmount?.toFixed(2)})</h3></Offcanvas.Header>
      <Offcanvas.Body>
        <Wizard
          render={({ step, steps }) => {
            return <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />
              <Steps>
                <Step
                  id="initial-setup"
                  render={({ next, previous }) => {
                    return <Row>
                      <Col lg="3"></Col>
                      <Col lg="6">
                        <Table>
                          <thead>
                            <tr >
                              <th>Product</th>
                              <th>Monthly Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{props.entityName}</td>
                              <td>ZAR {props.expenseAmount?.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </Table>
                        <div style={{ marginTop: 50, marginLeft: -10 }}>
                          <Input type="select" onChange={(e) => {
                            setMethod(e.target.value)
                          }} edit label="Select Payment Method" >
                            <option></option>
                            <option value="debit-order">Debit Order (SA Banks only)</option>
                            <option value="mastercard-visa">Mastercard / Visa</option>
                          </Input>
                          <Button style={{marginTop:10,marginLeft:10}} disabled={!method} onClick={next} >Continue</Button>
                        </div>
                      </Col>
                      <Col lg="3"></Col>
                    </Row>
                  }}
                />
                <Step
                  id="second-setup"
                  render={({ next, previous }) => {
                    if (method === "debit-order") {
                      return <InsuranceDebitOrder
                        next={next}
                        prev={previous}
                        {...props}
                        recurring={props.expenseAmount}
                        firstAmount={props.expenseAmount}
                      />
                    } else {
                      return <InsuranceVisa
                        next={next}
                        prev={previous}
                        {...props}
                        recurring={props.expenseAmount}
                        firstAmount={props.expenseAmount}
                      />
                    }
                  }}
                />
                <Step
                id="setup-complete"
                render={({next,previous})=>{
                    return <CompleteDO/>
                }}
                />
              </Steps>
            </React.Fragment>
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default InsuranceCanvas;