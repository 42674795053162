import React,{ useState ,useRef } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Input from '../Inputs/Input';
import Logo from '../../assets/logo.png';
import Agrostrong from '../../assets/agrostrong.png';
import ReactCodeInput from 'react-verification-code-input';
import LoadingBar from 'react-top-loading-bar';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import {
    NotificationContainer, NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {useEffect} from 'react';

const email = window.location.href.split('?')[1];


const OTP = (props) => {

  const email = props.location.search.split('?')[1];

  const [state,setState] = useState({email:email });

  const ref = useRef(null);

  const mutation = useMutation(Auth.OTP, {
        onSuccess: (s) => {
            setState({...state,redirectLogin:true});
        },
        onSettled: () => {
            ref.current.complete();
        }
  });

  useEffect(()=>{
      document.title = window.location.origin.includes('agrostrong') ? 'Agrostrong' :'Tsigiro Usekelo'
   },[]);

  const resendCode = useMutation(Auth.ResendOTP, {
    onSuccess: (s) => {
             NotificationManager.success(
              `please allow upto 30 seconds to receive OTP.`,
              "OTP resent successfully",
            );
        },
        onSettled: () => {
            ref.current.complete();
        },
  });

  const logoReturn = ()=>{
      if(window.location.origin.includes('agrostrong')){
        return Agrostrong
      }else{
        return Logo
      }
  };

  const submitOTP = () =>{
    mutation.mutate(state);
    ref.current.continuousStart()
  };    

  const resendOTP = ()=>{
    resendCode.mutate(state);
    ref.current.continuousStart()
  };

  return (
    <div className='container'>
      {/* <h3 style={{color:'#fff'}}>Explore Investment Opportunities with Tsigiro Today</h3> */}
      <NotificationContainer/>
      <div className='inner-container-register-otp'>
        {
          state.redirectLogin && <Redirect to="/" />
        }
        
        <LoadingBar color='green' progress={5} ref={ref} />
        <div className='overlay-inner-container-otp'>
                <img  src={logoReturn()} className='logo-md'/>
                <h3>Verify your email</h3>
                <h4>we sent a 6 digit code to your email, enter it below</h4>
                {
                  mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
                }
                <ReactCodeInput 
                className='otp-input'
                inputStyle={{fontFamily:'Montserrat-Regular',color:'green'}}
                onChange={(e)=>{
                  setState({...state,otpcode:e})
                }}/>
                 <button 
                 disabled={mutation.isLoading || !state.otpcode}
                 style={{backgroundColor:mutation.isLoading || state?.otpcode?.length < 6 || !state?.otpcode ? '#ccc' : '#47878a'}}
                 onClick={submitOTP}
                 className='btn btn-primary'>Verify</button>
                 <p style={{cursor:'pointer'}}>Didn't receive code ? <span onClick={resendOTP} style={{textDecoration:'underline',color:'green',cursor:'pointer'}}>Resend</span></p>
                 <p 
                 style={{cursor:'pointer',marginTop:20}}
                 onClick={()=>{
                  setState({redirectLogin:true});
                 }}>Already have an ID? <span style={{textDecoration:'underline'}}>Login</span></p>
        </div>
      </div>
         {window.location.origin.includes('agrostrong') && <p style={{marginTop:10}}>Powered by Tsigiro Usekelo</p>}
    </div>
  )
}

export default OTP