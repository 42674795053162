import React,{ useState ,useRef,useEffect} from 'react';
import {useMutation, useQuery} from 'react-query';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import LoadingBar from 'react-top-loading-bar';
import Auth from '../../../API/Auth';
import Navbar from '../../NavBar/NavBar';

const Notifications = () => {

   const [notifications, setNotifications] = useState([]);
   
   const ref = useRef(null);

    useQuery("notifications",Auth.fetchNotifications,{
        onSuccess:((x)=>{
            const notifies  = x.data.usernotifications;
            setNotifications([...notifies]);
        }),
        onSettled:((x)=>{
         ref.current.complete();
        })
    });

     useEffect((x)=>{
            ref.current.continuousStart();
    },[]);

    const notificationMutation = useMutation(Auth.updateNotification,{
    onSuccess:((x)=>{
         ref.current.complete();
    }),
    onError:((x)=>{

    }),
    onSettled:((x)=>{
         ref.current.complete();
    })
    });

    

    const onToggle = (id, option)=>{
            ref.current.continuousStart();
            notificationMutation.mutate(id);
    }
  return (
    <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container'>
                    <div>
        <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
        <div className='settings-component'>
            <div className='settings-component-inner-right'>
                <h2>Notifications</h2>
                <p>This tab gives you full control of all notification emails you receive</p>
            </div>
        </div>
        <div className='settings-component'>
            <div className='settings-component-inner-right'>
                <h2>Transactional</h2>
                <p>Receive notifications when any activity involving a transaction is made on your account</p>
            </div>
            <div className='settings-component-inner-left' style={{display:'flex',padding:20,alignItems:'center',justifyContent:'center',width:'100%',}}>
                <div className='notifications-container' >
                    {
                        notifications.filter((x)=> x.notification.type === "transactional" ).map((notification,index) => {
                            return (
                                <div className='notifications' key={index} style={{display:"flex",justifyContent:'space-between',marginTop:20}}>
                                    <div>
                                        <h3 style={{marginBottom:2,marginTop:0,color:'#47878a'}}>{notification.notification?.title}</h3>
                                        <p style={{margin:0,marginRight:5}}>{notification.notification?.description}</p>
                                    </div>
                                    <div>
                                        <Toggle 
                                        defaultChecked={notification.option}
                                        onChange={(x)=>{
                                            onToggle(notification._id,notification.option)
                                        }}
                                        id={notification.id}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
          </div>
      </div>
       <div className='settings-component'>
            <div className='settings-component-inner-right'>
                <h2>Marketing</h2>
                <p>Control how you would like to receive notifications when they are made available</p>
            </div>
            <div className='settings-component-inner-left' style={{display:'flex',padding:20,alignItems:'center',justifyContent:'center',width:'100%',}}>
                <div className='notifications-container'  >
                    {
                        notifications.filter((x)=> x.notification.type === "marketing" ).map((notification,index) => {
                            return (
                                <div className='notifications' key={index} style={{display:"flex",justifyContent:'space-between',marginTop:20}}>
                                    <div>
                                        <h3 style={{marginBottom:2,marginTop:0,color:'#47878a'}}>{notification.notification.title}</h3>
                                        <p style={{margin:0,marginRight:5}}>{notification.notification.description}</p>
                                    </div>
                                    <div>
                                        <Toggle 
                                        defaultChecked={notification.option}
                                        onChange={(x)=>{
                                            onToggle(notification._id,notification.option)
                                        }}
                                        id={notification.id}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
          </div>
      </div>
      <div className='settings-component'>
            <div className='settings-component-inner-right'>
                <h2>Account</h2>
                <p>control how you would like to receive notifications when any changes to your account are made</p>
            </div>
            <div className='settings-component-inner-left' style={{display:'flex',padding:20,alignItems:'center',justifyContent:'center',width:'100%',}}>
                <div className='notifications-container'  >
                    {
                        notifications.filter((x)=> x.notification.type === "account" ).map((notification,index) => {
                            return (
                                <div className='notifications' key={index} style={{display:"flex",justifyContent:'space-between',marginTop:20}}>
                                    <div>
                                        <h3 style={{marginBottom:2,marginTop:0,color:'#47878a'}}>{notification.notification.title}</h3>
                                        <p style={{margin:0,marginRight:5}}>{notification.notification.description}</p>
                                    </div>
                                    <div>
                                        <Toggle 
                                        defaultChecked={notification.option}
                                        onChange={(x)=>{
                                            onToggle(notification._id,notification.option)
                                        }}
                                        id={notification.id}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
          </div>
      </div>
    </div>

                </div>
            </div>
    </div>
    
  )
};

export default Notifications;