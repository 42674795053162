import React from 'react';
import {Button, Modal, Tab, Tabs} from 'react-bootstrap';
import FileUploader2 from '../Helpers/FileUploader2';
import Input from '../Inputs/Input';
import FundTransfer from './FundTransfer';
import InstantPayments from './InstantPayments';

const FundWalletModal = (props) => {
  return (
        <Modal
       style={{zIndex:10000}}
       show={props.show}
       onHide={props.onClose}
       size="l"
       >
            <Modal.Header
            closeButton
            style={{padding:20,backgroundColor:'#334155',color:'#fff',borderRadius:0}}
            
            >
            Fund your Tsigiro Usekelo Wallet
            </Modal.Header>
            <Modal.Body>
                <p>Deposit funds into your Tsigiro Usekelo Wallet</p>
                <Tabs
                className='mb-3'
                style={{fontSize:13}}
                >
                    <Tab
                    title={
                        <h5 style={{margin:0}}>Instant Payments</h5>
                    }
                    eventKey="instant-payment"
                    >
                   <InstantPayments/>
                    </Tab>
                    <Tab 
                    eventKey="bank-transfer"
                    title={"Bank Transfer"}
                    >
                    <FundTransfer
                    handleClose={props.onClose}
                    />
                    </Tab>
                    {/* <Tab 
                    eventKey="debit-orders"
                    title={
                        <h5 style={{margin:0}}>Debit Orders<span><i style={{color:'red',marginLeft:5}} className='dripicons-lock'></i></span></h5>
                    }
                    >
                    <p style={{marginLeft:10,marginBottom:20,color:'#3b3b3b'}}>Debit orders allow you to automatically deposit funds every month into Tsigiro Usekelo from your bank, you can manage your debit orders under billing and banking in your profile. ( Valid for SA banks only )</p>
                    <Input
                    type="date"
                    placeHolder="0.00"
                    min={0}
                    edit
                    label="Select Debit Order Date"
                    />
                    <Input
                    type="number"
                    placeHolder="0.00"
                    min={0}
                    edit
                    label="Enter Amount (ZAR)"
                    />
                    <div style={{marginTop:20,marginLeft:10,width:'100%'}}>
                    <button 
                    className='btn btn-primary'>
                        Pay Now
                    </button>
                    </div>
                    </Tab> */}
                </Tabs>
                
            </Modal.Body>
       </Modal>
  )
}

export default FundWalletModal