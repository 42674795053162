import React,{ useState, useRef} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Agrostrong from '../../assets/agrostrong.png';

import Input from '../Inputs/Input';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import {useEffect} from 'react';
//
const Registration = () => {
  
  const [state,setState] = useState({acceptedTermsAndConditions : false});
  const ref = useRef(null);

  const mutation = useMutation(Auth.Registration, {
      onSuccess: (s) => {
        setState({...state,redirectOTP:true});
      },
      onSettled: () => {
        ref.current.complete();
      }
   });

  const handleInput=(e)=>{
    setState({...state,[e.target.name]:e.target.value})
   };


  const handleRegistration = () => {
      ref.current.continuousStart()
      mutation.mutate(state);
  };


  const [passwordValidation, setPasswordValidation] = useState(false)
  const [emailValidation , setEmailValidation] = useState(false)

  const validatePassword = (password) => {
    var passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,200}$/;
    if(password.match(passw)) { 
      setPasswordValidation(true)
      console.log("strong")
    }
    else{ 
      setPasswordValidation(false);
    }
  }

  const validateEmail = (email) => {
    email = email.replace(/\s+/g,'');// remove all white spaces on the ID
    const valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(email))? true : false;
    setEmailValidation(valid);
    return valid;
  };

  useEffect(()=>{
      document.title = window.location.origin.includes('agrostrong') ? 'Agrostrong' :'Tsigiro Usekelo'
   },[]);

  
  const logoReturn = ()=>{
      if(window.location.origin.includes('agrostrong')){
        return Agrostrong
      }else{
        return Logo
      }
  };

  return (
    <div className='container'>
    {
        state.redirectOTP && <Redirect to={`/email-otp?${state.email}`}  />
    }
    {
        state.redirectLogin && <Redirect to="/" />
    }
      <div className='inner-container-register'>
        <LoadingBar color='green' progress={5} ref={ref} />
        <div className='overlay-inner-container'>
                <img  src={logoReturn()} className='logo-md'/>
                <h3>Register your new {window.location.origin.includes('agrostrong') ? "Agrostrong" :"Tsigiro Usekelo"} ID</h3>
                {
                  mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
                }
                    <Input type="text" label={(window.location.origin.includes('agrostrong') ? "Agrostrong / Tsigiro Usekelo" :"Tsigiro Usekelo") + " Username"} name="username" edit onChange={handleInput}/>
                    <Input error = {state?.email && !emailValidation? `Invalid email` : false } type="text" label="Your email address" name="email" edit onChange={event=>{handleInput(event); validateEmail(event.target.value)}}/>
                    <Input error={!passwordValidation && state.password || false?`Weak password!` : false} type="password" label="Password" name="password" edit onChange={(event)=> { validatePassword(event.target.value); handleInput(event);}}/>
                    {
                      !passwordValidation  &&
                      <span style={{fontSize:12, color:"red"}}>The passord must include at least 1 Capital Letter, 1 small letter, 1 number, 1 special character e.g {'('} ? . # $ {')'}</span>
                    }
                    <Input error={(state.password != state.confirmpass) && state.password || false?`Password do not match!` : false} type="password" label="Confirm Password" name="confirmpass" edit onChange={handleInput}/>
                 <button 

                 onClick={handleRegistration}
                 disabled={mutation.isLoading || !passwordValidation || !emailValidation || !state.username || !state.email || !state.password || !state["confirmpass"] || (state.password != state.confirmpass)}
                 style={{backgroundColor:mutation.isLoading || !state.username ||  !passwordValidation || !emailValidation  || !state.email || !state.password ||  (state.password != state.confirmpass) || !state["confirmpass"]  ? '#ccc' : '#47878a'}}
                 className='btn btn-primary'>Register
                 </button>
                 <p
                  style={{cursor:'pointer'}}
                    onClick={()=>{
                      setState({redirectLogin:true});
                 }}                 
                 >Already have an ID? <span style={{textDecoration:'underline'}}>Login</span></p>
        </div>
      </div>
    </div>
  )
};

export default Registration;