import ApiClient from "./Client";
import { getLoggedInUser } from "./Cookies";

const version = '/api/v1';

const requestParams = {
  headers: {
    'x-access-token': getLoggedInUser()?.token
  }
};

class Fundraiser {

    matchExisitingAccount = (data)=> ApiClient().post(`${version}/fundraiser/transaction/match`,data,requestParams);

    createNewAccount = (data)=> ApiClient().post(`${version}/fundraiser/new`,data,requestParams);

    getFundraiser = (id)=> ApiClient().get(`${version}/fundraiser/transaction/${id}`,requestParams);

    matchNewAccount = (data)=> ApiClient().post(`${version}/fundraiser/transaction/match-new`,data,requestParams);

}


export default new Fundraiser();
