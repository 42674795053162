import React,{ useRef,useEffect, useState } from 'react'
import {countries} from '../../contants/countries';
import FileUploader from '../../Helpers/FileUploader';
import Input from '../../Inputs/Input';
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {AccountTypes} from '../../contants/accountTypes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
 import { faEdit,faCircleInfo, faCreditCard, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AccountTypeModal from './AccountTypeModal';
import FileUploader2 from '../../Helpers/FileUploader2';
import {useMutation, useQuery} from 'react-query';
import Auth from '../../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import Navbar from '../../NavBar/NavBar';

const BillingAndBanking = () => {

   const [EditBankDetails, setEditBankDetails] = React.useState(false);
   const [modalIsOpen, setModalIsOpen] = React.useState(false);
   const [bankingState,setBankingState] = useState({});
   const [selectedInfo, setSelectedInfo] = React.useState(0);
   const [bankingDetails,setBankingDetails] = useState({});

   const ref = useRef(null);

   useQuery("bankingDetails",Auth.fetchBankingDetails,{

    onSettled:()=>{
      ref.current.complete();
    },

    onError:()=>{
      ref.current.complete();
    },

    onSuccess:((x)=>{
        const bank = x.data.bank;
        setBankingDetails(bank || {});
    })

   });


   const bankingMutation = useMutation(Auth.UpdateBankingDetails,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        const bank = x.data.bank;
        setBankingDetails(bank || {});
        setEditBankDetails(false);
    }),
    onError:((x)=>{
        setEditBankDetails(false);
    })
   });

   const handleInputChange =(e)=>{
        setBankingState({...bankingState,[e.target.name]:e.target.value});
   };

   useEffect((x)=>{
            ref.current.continuousStart();
    },[]);

    
    const handleBankingUpdate = () => {
        var formData = new FormData();

        (bankingState.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in bankingState){
        formData.append(key,bankingState[key]);
        }
        
        ref.current.continuousStart();

        bankingMutation.mutate(formData);
    };

  return (
      <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container'>
                     <div style={{maxheight:'100vh'}}>
            <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
           <AccountTypeModal
           modalIsOpen={modalIsOpen}
           onModalClose={() => setModalIsOpen(false)}
           name={AccountTypes[selectedInfo].name}
           description={AccountTypes[selectedInfo].description}
           />
            <div className='settings-component'>
                {!EditBankDetails && <div
                     onClick={() => setEditBankDetails(true)}
                     className='edit-button'>
                    <button className='btn'>
                    <FontAwesomeIcon icon={faEdit} size="1x" style={{cursor:'pointer',marginRight:10}}/>
                        Edit</button>
                </div>}
                <div className='settings-component-inner-right'>
                    <h2>Bank Details</h2>
                    <p>Manage banking details from this tab</p>
                  <div style={{ width: 200, height: 200 }} className="circular-bar">
                  {/* <CircularProgressbar 
                    styles={buildStyles({
                        pathColor: `#ea9342`,
                        textSize: '8px',
                        textColor: '#000',
                    })}
                    text={`${54}% completed`}
                    value={54} 
                   /> */}
                    </div>
                </div>
                 <div className='settings-component-inner-left'>
                    <div className='flex-lg'>
                    <Input 
                    edit={EditBankDetails}
                    defaultValue={bankingDetails.accountName}
                    fieldValue={bankingDetails.accountName}
                    onChange={handleInputChange}
                    type="text" name="accountName" placeholder="" label="Account Name"  style={{marginRight:10}}/>
                    <br></br>
                    <Input 
                    edit={EditBankDetails}
                    defaultValue={bankingDetails.bankName}
                    fieldValue={bankingDetails.bankName}
                    onChange={handleInputChange}
                    type="text" name="bankName" placeholder="" label="Bank Name"  />
                    </div>
                     <div className='flex-lg'>
                    <Input 
                    edit={EditBankDetails}
                    onChange={handleInputChange}
                    defaultValue={bankingDetails.accountNumber}
                    fieldValue={bankingDetails.accountNumber}
                    name="accountNumber"
                    type="text"  placeholder="" label="Account Number" style={{marginRight:10}}/>
                    <Input 
                    edit={EditBankDetails}
                    onChange={handleInputChange}
                    defaultValue={bankingDetails.swiftCode}
                    fieldValue={bankingDetails.swiftCode}
                    name="swiftCode"
                    type="text"  placeholder="" label="Swift Code"  />
                    </div>
                     <div className='flex-lg'>
                    <Input 
                    edit={EditBankDetails}
                    onChange={handleInputChange}
                    defaultValue={bankingDetails.branchName}
                    fieldValue={bankingDetails.branchName}
                    name="branchName"
                    type="text" label="Branch Name" style={{marginRight:10}} />
                     <Input 
                    onChange={handleInputChange}
                    name="country"
                    defaultValue={bankingDetails.country}
                    fieldValue={bankingDetails.country}
                     edit={EditBankDetails} type="select"  placeholder="Zimbabwe" label="Country" style={{marginRight:10}}>
                       {
                            countries.map((country, index) => {
                                return <option value={country.name}>{country.name}</option>
                            })
                       }
                    </Input>
                    </div>
                    { EditBankDetails ? 
                    !bankingDetails.bankVerifiedStatus === "rejected" || !bankingDetails.bankVerifiedStatus  &&
                    <FileUploader2 
                    onFileUpload={(x)=>setBankingState({...bankingState,files:x})}
                    title="Proof of Bank Account (upload bank statement)"/> 
                    : <div className='file-uploader' style={{borderColor:'green'}}>
                        <h3 style={{textAlign:'center'}}>{bankingDetails.bankVerifiedStatus  === "submitted" ? "Proof of bank account pending approval": 
                        bankingDetails.bankVerifiedStatus  === "approved" ? "Proof of bank account verified":  "Proof of bank account not verified"}</h3>
                        {bankingDetails.bankVerifiedStatus  === "submitted"  ?  
                         <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{color:'orange',marginTop:-12}}/>
                        : bankingDetails.bankVerifiedStatus  === "approved"  ? 
                             <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{color:'green',marginTop:-12}}/> :
                        <p style={{textAlign:'center'}}>Upload your bank statement to verify your bank account</p>}
                        </div>}
                    {EditBankDetails && <div>
                        <button 
                        onClick={handleBankingUpdate}
                        className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,marginRight:10}}>Save</button>
                        <button 
                        onClick={()=>{
                            setEditBankDetails(false);
                        }}
                        className='btn btn-primary' 
                        style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}
                        >Cancel</button>
                    </div>}
                    
                </div>
            </div>
             <div className='settings-component'>
                <div className='settings-component-inner-right billing'>
                    <h2>Debit Orders</h2>
                    <p>Manage all investments that are to be<br></br> debited from this tab
                    </p>
                 
                </div>
                 <div className='settings-component-inner-left billing'>
                       <div className='empty-profile-group'>
                        <FontAwesomeIcon icon={faCreditCard} size="3x" style={{color:'#e0e0e0'}}/>
                        <h3>No Investments with Debit Orders Available</h3>
                    </div>
                </div>
            </div>
            <div className='settings-component'>
                <div className='settings-component-inner-right billing'>
                    <h2>Subcriptions</h2>
                    <p>Manage all subscription payments<br></br> from this tab
                    </p>
                </div>
                 <div className='settings-component-inner-left billing'>
                       <div className='empty-profile-group'>
                        <FontAwesomeIcon icon={faCreditCard} size="3x" style={{color:'#e0e0e0'}}/>
                        <h3>You are currently not subscribed to any investment opportunity</h3>
                    </div>
                </div>
            </div>
        </div>
                </div>
            </div>
        </div>
       

  );

}

export default BillingAndBanking