import React,{ useState, useRef} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import FullLogo from '../../assets/full.logo.svg';
import Input from '../Inputs/Input';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import '../styles/registration.success.css'
const SuccessfulRegistration = () => {
  
  const [state,setState] = useState({acceptedTermsAndConditions : false});
  const ref = useRef(null);

  const mutation = useMutation(Auth.Registration, {
      onSuccess: (s) => {
        setState({...state,redirectOTP:true});
      },
      onSettled: () => {
        ref.current.complete();
      }
   });

  const handleInput=(e)=>{
    setState({...state,[e.target.name]:e.target.value})
   };


  const handleRegistration = () => {
      ref.current.continuousStart()
      mutation.mutate(state);
  };


  const [passwordValidation, setPasswordValidation] = useState(false)
  const [emailValidation , setEmailValidation] = useState(false)

  const validatePassword = (password) => {
    var passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,200}$/;
    if(password.match(passw)) { 
      setPasswordValidation(true)
      console.log("strong")
    }
    else{ 
      setPasswordValidation(false);
    }
  }

  const validateEmail = (email) => {
    email = email.replace(/\s+/g,'');// remove all white spaces on the ID
    const valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))? true : false;
    setEmailValidation(valid);
    return valid;
  }

  return (
    <div>
      <LoadingBar color='green' progress={5} ref={ref} />
      {
          state.redirectOTP && <Redirect to={`/email-otp?${state.email}`}  />
      }
      {
          state.redirectLogin && <Redirect to="/" />
      }
      <div style={{display: "flex"}}>
        <div  style={{height : "100vh"}} className='hero-template reg-success-container-1'>
          <div className='hero-text'>
            <div className='hero-row hero-tittle '>
              Helping all Zimbabwean Diaspora build or buy a, back home
            </div>
            <div className='hero-row hero-description'>
              Get a comprehensive solution designed to support you from the diaspora.
            </div>
            <div className='hero-row'>
              Learn more
            </div>
          </div>
        </div>

        <div className='container reg-success-container-2' style={{justifyContent:"normal", paddingTop:"7%"}}>
            <img alt='Stigiro | Usekelo' src={FullLogo} style={{width : "30%", marginBottom : "1.4%"}} className='logo-md'/>
            <div style={{textAlign:"center"}}>
              <div className='success-page-tittle'>Registration Successful</div>
              <div className='success-page-sub-tittle'>Click the button below to go to login</div>
            </div>
            <Link to='/login' style={{width :"100%", textAlign : "center"}}>
                <button 
                  onClick={()=>{}}
                  style={{backgroundColor: '#47878a', width :"fit-content", fontWeight:"bolder", paddingLeft:"3%", paddingRight:"3%"}}
                  className='btn btn-primary'>Go to Login Page
                </button>
            </Link>
        </div>
      </div>
    </div>
  )
};

export default SuccessfulRegistration;