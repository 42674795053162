import React, {useEffect,useRef, useState} from 'react'
import {countries} from '../../contants/countries';
import FileUploader from '../../Helpers/FileUploader';
import Input from '../../Inputs/Input';
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {AccountTypes} from '../../contants/accountTypes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
 import { faEdit,faCircleInfo, faListCheck, faCheckCircle, faAnchorCircleCheck } from '@fortawesome/free-solid-svg-icons';
import AccountTypeModal from './AccountTypeModal';
import {useMutation, useQuery} from 'react-query';
import Auth from '../../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import FileUploader2 from '../../Helpers/FileUploader2';
import Navbar from '../../NavBar/NavBar';
import { parseDOB, parseCitizenship, parseGender, validateIdNumber } from "south-african-id-validator";

const Personal = () => {
    
   const [selectedAccountType, setSelectedAccountType] = React.useState(-1);
   const [editPersonal, setEditPersonal] = React.useState(false);
   const [modalIsOpen, setModalIsOpen] = React.useState(false);
   const [selectedInfo, setSelectedInfo] = React.useState(0);
   const [state,setState] = React.useState({});
   const [personalDetails, setPersonalState] = React.useState({});
   const [personalCompletedStatus,setPersonalCompletedStatus] = React.useState(0);
   const [editProfileType,setEditProfileType] = React.useState(false);
   const [accountTypeState, setAccountTypeState] = React.useState({});
   const [profileTypeCompletionStatus,setProfileTypeCompletionStatus] = React.useState(0);
   const [addressState, setAddressState] = React.useState({});
   const [addressDetails, setAddressDetails] = React.useState({});
   const [editAddress, setEditAddress] = React.useState(false);
   const [addressCompletionStatus, setAddressCompletionStatus] = React.useState(0);
   const ref = useRef(null);
    useQuery("personalDetails",Auth.Me,{
    onSettled:((x)=>{
      ref.current.complete();
    }),

    onSuccess:((x)=>{
        const user = x.data.user;
        setAddressState(user.address || {});
        setState(user);
    }),
   });

   const personalMutate = useMutation(Auth.UpdatePersonalDetails,{
     onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        const user = x.data.user;
        setEditPersonal(false);
        setState(user);
    }),
    onError:((x)=>{
         setEditPersonal(false);
    })
   });

   const addressMutate = useMutation(Auth.UpdateAddressDetails,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        const address = x.data.address;
        setEditAddress(false);
        setAddressState(address);
    }),
    onError:((x)=>{
         setEditPersonal(false);
    })
   })
   
   useEffect(()=>{
       setPersonalCompletedStatus(personalDetailsCompletionStatus());
   },[state]);

   useEffect(()=>{
       setProfileTypeCompletionStatus(profileTypeCompletion());
   },[accountTypeState]);

   useEffect(()=>{
       setAddressCompletionStatus(handleAddressCompletionStatus());
   },[addressState]);

   useEffect(()=>{
      ref.current.continuousStart();
   },[]);

   const handleInputChange =(e)=>{
        setPersonalState({...personalDetails,[e.target.name]:e.target.value});
   };

 

    const handleAddressInputChange =(e)=>{
        setAddressDetails({...addressDetails,[e.target.name]:e.target.value})
   };

   const handlePersonalDetailsSave = () => {
      
        var formData = new FormData();
        (personalDetails.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in personalDetails){
        formData.append(key,personalDetails[key]);
        }
        ref.current.continuousStart();
        personalMutate.mutate(formData);
   };

   const handleAccountTypeSave =()=>{
        setEditProfileType(false);
        ref.current.continuousStart();
        personalMutate.mutate({profileType:accountTypeState.type,profileTypeName:accountTypeState.name || ''});
   };

   const handleAddressSave =()=>{
       var formData = new FormData();
        (addressDetails.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in addressDetails){
        formData.append(key,addressDetails[key]);
        }
        ref.current.continuousStart();
        addressMutate.mutate(formData);
   }

   const profileTypeCompletion = ()=>{
     let percentage = 0;
     const requires = AccountTypes[AccountTypes.map((x)=> {return x.name}).indexOf(state.profileType)]?.requiresName;
     const divident = requires ? 2 : 1;
     if(requires){
     if(state.profileType)percentage += 100/divident;
     if(state.profileTypeName)percentage += 100/divident;
     }else{
     if(state.profileType)percentage += 100/divident;
     }
     
     return parseInt(percentage);
   }

   const handleAddressCompletionStatus = ()=>{
    let percentage = 0;
    const divident = 13;
    if(addressState.address)percentage += 100/divident;
    if(addressState.address?.length > 3)percentage += 100/divident;
    if(addressState.city)percentage += 100/divident;
    if(addressState.city?.length > 3)percentage += 100/divident;
    if(addressState.country)percentage += 100/divident;
    if(addressState.country?.length > 3)percentage += 100/divident;
    if(addressState.zip)percentage += 100/divident;
    if(addressState.zip?.length > 3)percentage += 100/divident;
    if(addressState.state)percentage += 100/divident;
    if(addressState.state?.length > 3)percentage += 100/divident;
    if(addressState.appartmentSuite)percentage += 100/divident;
    if(addressState.appartmentSuite?.length > 3)percentage += 100/divident;
    if(state.addressVerifiedStatus === "approved")percentage += 100/divident;
    return parseInt(percentage);
   };

   const personalDetailsCompletionStatus =()=>{
        let percentage = 0;
        const divident = 19;
        if(state.firstName)percentage += 100/divident;
        if(state?.firstName?.length > 3)percentage += 100/divident;
        if(state.lastName)percentage += 100/divident;
        if(state?.lastName?.length > 3)percentage += 100/divident;
        if(state.email)percentage += 100/divident;
        if(state.email?.length > 3)percentage += 100/divident;
        if(state.phone)percentage += 100/divident;
        if(state.phone?.length > 3)percentage += 100/divident;
        if(state.DOB)percentage += 100/divident;
        if(state.DOB?.length > 3)percentage += 100/divident;
        if(state.nationalId?.length > 3)percentage += 100/divident;
        if(state.nationalId)percentage += 100/divident;
        if(state.nationalCountryOfIssue)percentage += 100/divident;
        if(state.nationalCountryOfIssue?.length > 3)percentage += 100/divident;
        if(state.maritalStatus?.length > 3)percentage += 100/divident;
        if(state.maritalStatus)percentage += 100/divident;
        if(state.occupation?.length > 3)percentage += 100/divident;
        if(state.occupation)percentage += 100/divident;
        if(state.identityVerifiedStatus === "approved")percentage += 100/divident;
        return parseInt(percentage);
    };

      const typeDate = (dates)=>{
        const date = new Date(dates);
        return date.getFullYear() +"-"+date.getMonth()+"-"+date.getDate();
    }

    const [isValidNationalID, setIsValidNationalID] = useState(true)

   return (
    <>
        <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container'>
                <div style={{maxheight:'100vh'}}>
                        <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
                    <AccountTypeModal
                    modalIsOpen={modalIsOpen}
                    onModalClose={() => setModalIsOpen(false)}
                    name={AccountTypes[selectedInfo].name}
                    description={AccountTypes[selectedInfo].description}
                    />
                        <div className='settings-component'>
                            {!editPersonal && <div
                                onClick={() => setEditPersonal(true)}
                                className='edit-button'>
                                <button className='btn' >
                                <FontAwesomeIcon icon={faEdit} size="1x" style={{cursor:'pointer',marginRight:10}}/>
                                    Edit
                                </button>
                            </div>
                            }
                            <div className='settings-component-inner-right'>
                                <h2>Personal Details</h2>
                                <p>manage personal details from this tab</p>
                            <div style={{ width: 200, height: 200 }} className="circular-bar">
                            <CircularProgressbar 
                                styles={buildStyles({
                                    pathColor:personalCompletedStatus < 35 ? `#efb951` : personalCompletedStatus < 75 ? `#ea9342` : `#47878a`,
                                    textSize: '8px',
                                    textColor: '#000',
                                })}
                            text={`${personalCompletedStatus}% completed`}
                            value={personalCompletedStatus} 
                        />
                            </div>
                        </div>
                        <div className='settings-component-inner-left'>
                            <div className='flex-lg'>
                            <Input 
                            defaultValue={state.firstName}
                            onChange={handleInputChange}
                            fieldValue={state.firstName}
                            type="text" name="firstName" placeholder="" label="First Name"  style={{marginRight:10}} edit={editPersonal} />
                            <Input 
                            onChange={handleInputChange}
                            defaultValue={state.lastName}
                            fieldValue={state.lastName}
                            type="text" name="lastName" placeholder="" label="Last Name" edit={editPersonal} />
                            </div>
                            <div className='flex-lg'>
                            <Input 
                            onChange={handleInputChange}
                    defaultValue={state.email}
                    fieldValue={state.email}
                    name="email"
                    type="text"  placeholder="" label="Email" style={{marginRight:10}} edit={editPersonal}/>
                    <Input 
                    onChange={handleInputChange}
                    defaultValue={state.phone}
                    fieldValue={state.phone}
                    name="phone"
                    type="text"  placeholder="" label="Phone" edit={editPersonal} />
                    </div>
                     <div className='flex-lg'>
                    <Input 
                        onChange={(e)=>{
                            setIsValidNationalID(validateNationalZIMId(e.target.value) || validatePassportNumberZim(e.target.value) || validateIdNumber(e.target.value).valid)
                            handleInputChange(e);
                        }}

                        error = {!isValidNationalID ? "Invalid National ID Number" : "" }
                        name="nationalId"
                        defaultValue={state.nationalId}
                        fieldValue={state.nationalId}
                        type="text"  placeholder="" label="ID or Passport number"  edit={editPersonal}/>
                     <Input 
                        onChange={handleInputChange}
                        name="nationalCountryOfIssue"
                        defaultValue={state.nationalCountryOfIssue}
                        fieldValue={state.nationalCountryOfIssue}
                        type="select"  label="ID or Passport Country Of Issue" style={{marginRight:10}} edit={editPersonal}>
                        {
                                countries.map((country, index) => {
                                    return <option key={index} value={country.name}>{country.name}</option>
                                })
                        }
                    </Input>
                    </div>
                    <div className='flex-lg'>
                    <Input 
                    defaultValue={state.DOB}
                    fieldValue={state.DOB}
                    onChange={handleInputChange}
                    name="DOB"
                    type="date" label="Date of birth" style={{marginRight:10}} edit={editPersonal}/>
                    <Input
                     onChange={handleInputChange}
                     name="maritalStatus"
                     defaultValue={state.maritalStatus}
                     fieldValue={state.maritalStatus}
                     type="select"  label="Marital Status" style={{marginRight:10}} edit={editPersonal}>
                        <option value="single">
                            Single
                        </option>
                        <option value="married">
                            Married
                        </option>
                        <option value="divorced">
                            Divorced
                        </option>
                        <option value="widowed">
                            Widowed
                        </option>
                        <option value="rather_not_say">
                            Rather not say
                        </option>
                    </Input>
                    </div>
                     <div className='flex-lg'>
                        <Input 
                        onChange={handleInputChange}
                        name="occupation"
                        fieldValue={state.occupation}
                        type="text"  placeholder="" label="Occupation" style={{marginRight:10}} edit={editPersonal} 
                        defaultValue={state.occupation}/>
                    </div>
                    {editPersonal ? 
                    (state.identityVerifiedStatus === "rejected" || !state.identityVerifiedStatus) &&
                    <FileUploader2 
                    onFileUpload={(x)=>setPersonalState({...personalDetails,files:x})}
                    title="Proof of Indentity (upload Id card or passport)"/> 
                    : <div className='file-uploader' style={{borderColor:'green'}}>
                        <h3 style={{textAlign:'center'}}>{state.identityVerifiedStatus === "submitted" ? "Proof of identity pending approval": 
                        state.identityVerifiedStatus === "approved" ? "Proof of identity verified":  "Proof of identity not verified"}</h3>
                        {state.identityVerifiedStatus === "submitted"  ?  
                         <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{color:'orange',marginTop:-12}}/>
                        : state.identityVerifiedStatus === "approved"  ? 
                             <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{color:'green',marginTop:-12}}/> :
                        <p style={{textAlign:'center'}}>Upload your id or passport to verify your identity</p>}
                        </div>}
                    {editPersonal && 
                    <div>
                        <button 
                         disabled={personalMutate.isLoading || !isValidNationalID}
                         style={{backgroundColor:personalMutate.isLoading ? '#ccc' : '#47878a',width:100,marginTop:30,marginBottom:0,marginRight:10}}
          
                        onClick={handlePersonalDetailsSave} className='btn btn-primary'>Save</button>
                        <button onClick={()=>{
                            setPersonalState(state);
                            setEditPersonal(false)}} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                    </div>
                    }
                </div>
            </div>
             <div className='settings-component'>
                 {!editProfileType && <div
                     onClick={() => setEditProfileType(true)}
                     className='edit-button'>
                    <button className='btn'>
                    <FontAwesomeIcon icon={faEdit} size="1x" style={{cursor:'pointer',marginRight:10}}/>
                        Edit</button>
                </div>}
                <div className='settings-component-inner-right'>
                    <h2>Profile Type</h2>
                    <p>Manage your account type from this tab. Changing your account type will require 
                        approval from our team. You will be notified once your account type has been changed.
                    </p>
                  <div style={{ width: 200, height: 200 }} className="circular-bar">
                  <CircularProgressbar 
                   styles={buildStyles({
                        pathColor:profileTypeCompletion() < 35 ? `#efb951` : profileTypeCompletion() < 75 ? `#ea9342` : `#47878a`,
                        textSize: '8px',
                        textColor: '#000',
                    })}
                    text={`${profileTypeCompletion()}% completed`}
                    value={profileTypeCompletion()}
                   />
                    </div>
                </div>
                 <div className='settings-component-inner-left'>
                    <div className='account-types'>
                    {
                        
                        editProfileType ?
                        AccountTypes.map((item,index) => {
                                 return <div>
                                            <div 
                                                key={index}  className='account-type-select'>
                                                    <div 
                                                    onClick={() => {
                                                            setSelectedAccountType(index);
                                                            setAccountTypeState({...selectedAccountType,type:item.name,requiresName:item.requiresName});
                                                        }}
                                                    className='inner-acccount-type'>
                                                    <div className={selectedAccountType === index ? 'account-type-selected':'account-type-not-selected'}></div>
                                                    <p style={{margin:0,width:100}}>{item.name}</p>
                                                    </div>
                                                <FontAwesomeIcon 
                                                onClick={() => 
                                                    {
                                                        setSelectedInfo(index)
                                                        setModalIsOpen(true)}
                                                    }
                                                icon={faCircleInfo} size="1x" style={{cursor:'pointer'}}/>
                                            </div>
                                            {item.requiresName && selectedAccountType === index &&  
                                            <Input 
                                            style={{marginLeft:-10}}
                                            type="text"
                                            onChange={(e)=>{
                                                setAccountTypeState({
                                                    name:e.target.value,
                                                    description:item.description,
                                                    type:item.name,
                                                    requiresName:item.requiresName
                                                })
                                            }}
                                            edit 
                                            placeholder={"Enter " + item.name + " name"}/>}
                                        </div>
                        }) : 
                       <div className='file-uploader' style={{borderColor:'green',marginTop:80,border:'1px solid green'}}>
                        <h3 style={{textAlign:'center'}}>{state.profileType || 'No Profile Type Selected'}</h3>
                       <strong>{state.profileTypeName && <p style={{textAlign:'center'}}>{state.profileTypeName}</p>}</strong> 
                       {/* <p style={{textAlign:'center'}}>{!state.profileType && accountTypeState.description || 'edit this section to add your profile type'}</p> */}
                        </div>

                    }
                    </div>
                    {editProfileType && 
                    <div>
                        <button onClick={handleAccountTypeSave} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,marginRight:10}}>Save</button>
                        <button onClick={()=>{
                            setEditProfileType(false)}} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                    </div>
                    }
                </div>
            </div>
            <div className='settings-component'>
                {!editAddress && <div
                     onClick={() => setEditAddress(true)}
                     className='edit-button'>
                    <button className='btn'>
                    <FontAwesomeIcon icon={faEdit} size="1x" style={{cursor:'pointer',marginRight:10}}/>
                        Edit</button>
                </div>}
                <div className='settings-component-inner-right'>
                    <h2>Address</h2>
                    <p>Manage your address and proof of<br></br> address from this tab</p>
                    <div style={{ width: 200, height: 200 }} className="circular-bar">
                  <CircularProgressbar 
                    styles={buildStyles({
                        pathColor: addressCompletionStatus < 35 ? `#efb951` : addressCompletionStatus < 75 ? `#ea9342` : `#47878a`,
                        textSize: '8px',
                        textColor: '#000',
                    })}
                    text={`${addressCompletionStatus}% completed`}
                    value={addressCompletionStatus} 
                   />
                    </div>
                </div>
                 <div className='settings-component-inner-left'>
                    <div className='flex-lg'>
                    <Input 
                    onChange={handleAddressInputChange}
                    defaultValue={addressState.address}
                    fieldValue={addressState.address}
                    name="address"
                    type="text"  placeholder="Address" label="Address" style={{marginRight:10}} edit={editAddress} />
                    <Input
                    onChange={handleAddressInputChange}
                    name="appartmentSuite"
                    deafultValue={addressState.appartmentSuite}
                    fieldValue={addressState.appartmentSuite}
                    type="text"  placeholder="Appartment" label="Appartment,suite etc" edit={editAddress}  />
                    </div>
                    <div className='flex-lg'>
                     <Input 
                    onChange={handleAddressInputChange}
                    name="country"
                    defaultValue={addressState.country}
                    fieldValue={addressState.country}
                    type="select"  placeholder="Zimbabwe" label="Country" style={{marginRight:10}} edit={editAddress} >
                       {
                            countries.map((country, index) => {
                                return <option key={index} value={country.name}>{country.name}</option>
                            })
                       }
                    </Input>
                    <Input
                    defaultValue={addressState.city}
                    fieldValue={addressState.city}
                    onChange={handleAddressInputChange}
                    name="city"
                    type="text"  placeholder="harare" label="City" edit={editAddress} />
                    </div>
                    <div className='flex-lg'>
                    <Input 
                    onChange={handleAddressInputChange}
                    defaultValue={addressState.zip}
                    fieldValue={addressState.zip}
                    name="zip"
                    type="text"  placeholder="00000" label="Zip"  style={{marginRight:10}} edit={editAddress} />
                    <Input 
                    onChange={handleAddressInputChange}
                    defaultValue={addressState.state}
                    fieldValue={addressState.state}
                    name="state"
                    type="text"  
                    placeholder="state..." 
                    label="State" 
                    edit={editAddress}
                      />
                    </div>
                     {editAddress ?
                    !addressState.addressVerifiedStatus=== "rejected" || !addressState.addressVerifiedStatus && 
                    <FileUploader2
                    onFileUpload={(x)=>setAddressDetails({...addressDetails,files:x})}
                    title="Upload Your Proof of Address"
                    /> 
                    : <div className='file-uploader' style={{borderColor:'green'}}>
                        <h3 style={{textAlign:'center'}}>{addressState.addressVerifiedStatus === "approved"  ? "Proof of address verified": addressState.addressVerifiedStatus=== "submitted" ? "Proof of address pending approval" : "Proof of address not verified"}</h3>
                         {addressState.addressVerifiedStatus === "submitted"  ?  
                         <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{color:'orange',marginTop:-12}}/>
                        : state.addressVerifiedStatus === "approved"  ? 
                             <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{color:'green',marginTop:-12}}/> :
                        <p style={{textAlign:'center'}}>Upload bank statement, water bill or electric bill</p>} </div>}
                    {editPersonal && 
                    <div>
                        <button onClick={handleAddressSave} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,marginRight:10}}>Save</button>
                        <button onClick={()=>{
                            setState({});
                            setEditPersonal(false)}} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                    </div>
                    }
                    {editAddress && 
                    <div>
                        <button
                        onClick={handleAddressSave} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,marginRight:10}}>Save</button>
                        <button onClick={()=>{
                            setAddressState(addressState);
                            setEditAddress(false)}} className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                    </div>
                    }
                </div>
            </div>
        </div>
                </div>
            </div>
        </div>
    </>
       

  );

}

export default Personal;

export const validateNationalZIMId  = (nationalId) => {
    nationalId = nationalId.replace(/\s+/g,'');// remove all white spaces
    nationalId = nationalId.replace(/-/g,''); //remove all dashes
    console.log (nationalId)
    const regex = /([0-9]{2}-?[0-9]{6,7}-?[a-z,A-Z]-?[0-9]{2})/g;
    console.log(nationalId.match(regex));
    return nationalId.match(regex)?.length == 1 && (nationalId.split("").length == 12  || nationalId.split("").length == 11 )
}

export const validatePassportNumberZim  = (nationalId) => {

    nationalId = nationalId.replace(/\s+/g,'');// remove all white spaces

    const regex = /([a-z,A-Z]{2}[0-9]{6})/g;

    return nationalId.match(regex)?.length == 1 && nationalId.split("").length == 8
}