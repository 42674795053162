import React,{ useState ,useRef } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Input from '../Inputs/Input';
import Logo from '../../assets/logo.png';
import Agrostrong from '../../assets/agrostrong.png';
import ReactCodeInput from 'react-verification-code-input';
import LoadingBar from 'react-top-loading-bar';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import {useEffect} from 'react';

const email = window.location.href.split('?')[1];

const ForgotPassword = (props) => {

  const [state,setState] = useState({ });

  const ref = useRef(null);

  const mutation = useMutation(Auth.ForgotPassword, {
        onSuccess: (s) => {
            setState({...state,redirectOTPFORGOT:true});
        },
        onSettled: () => {
            ref.current.complete();
        }
  });

  useEffect(()=>{
      document.title = window.location.origin.includes('agrostrong') ? 'Agrostrong' :'Tsigiro Usekelo'
   },[]);

  const submitOTP = () =>{
    mutation.mutate(state);
    ref.current.continuousStart()
  };    

   const handleForminput = (e) => {
    const {name,value} = e.target;
    setState({...state,[name]:value});
  };

   const logoReturn = ()=>{
      if(window.location.origin.includes('agrostrong')){
        return Agrostrong
      }else{
        return Logo
      }
  };


  return (
    <div className='container'>
      {/* <h3 style={{color:'#fff'}}>Explore Investment Opportunities with Tsigiro Today</h3> */}
      <div className='inner-container-register-otp'>
        {
          state.redirectOTPFORGOT && <Redirect to={`/otp-forgot-password?${state.email}`} />
        }

        {
          state.redirectLogin && <Redirect to="/login" />
        }
        <LoadingBar color='green' progress={5} ref={ref} />
        <div className='overlay-inner-container-otp'>
                <img  src={logoReturn()} className='logo-md'/>
                <h3>Tsigiro Usekelo Password Reset</h3>
                <h4>Enter details below to receive password request instructions</h4>
                {
                  mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
                }
                 <Input type="text" label="Email" edit name="email" value={state.email} onChange={handleForminput} />
                 <button 
                 disabled={mutation.isLoading || !state.email}
                 style={{backgroundColor:mutation.isLoading || state?.email?.length < 6 || !state?.email ? '#ccc' : '#47878a'}}
                 onClick={submitOTP}
                 className='btn btn-primary'>Submit</button>
                 <p 
                 style={{cursor:'pointer'}}
                 onClick={()=>{
                  setState({redirectLogin:true});
                 }}><span style={{textDecoration:'underline'}}>Login</span></p>
        </div>
      </div>
         {window.location.origin.includes('agrostrong') && <p style={{marginTop:10}}>Powered by Tsigiro Usekelo</p>}
    </div>
  )
}

export default ForgotPassword