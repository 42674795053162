import React,{useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUserFriends, faEdit, faSquarePen, faWarning, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import Input from '../../Inputs/Input';
import Multiselect from 'multiselect-react-dropdown';
import {PERMISSIONS} from '../../contants/permissions';
import {AccountTypes} from '../../contants/accountTypes';
import {countries} from '../../contants/countries';
import Auth from '../../../API/Auth';
import {useMutation, useQuery} from 'react-query';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import Navbar from '../../NavBar/NavBar';
import { Button, Modal } from 'react-bootstrap';
import { validateIdNumber } from 'south-african-id-validator';
import { validateNationalZIMId, validatePassportNumberZim } from './Personal';
import FileUploader2 from '../../Helpers/FileUploader2';

const SecondaryAccounts = () => {

 const [newProfileGroup, setNewProfileGroup] = React.useState(false);
 const [editProfileGroup, setEditProfileGroup] = React.useState(false);
 const [editProfileGroupMember, setEditProfileGroupMember] = React.useState(false);
 const [profileGroups, setProfileGroups] = React.useState([]);
 const [state, setState] = React.useState({});
 const [selected, setSelected] = React.useState([]);
 const [enableUserQuery, setEnableUserQuery] = React.useState(true)
 const ref = useRef(null);

 const {refetch} = useQuery("profileGroups", Auth.allProfileGroupsAndData ,{
    onSettled:((x)=>{
      ref.current.complete();

    }),
    onSuccess:((x)=>{
        const profiles = x.data.profileGroups;
        setProfileGroups(profiles);
    }),
    enabled : enableUserQuery
 })

 useEffect((x)=>{
        ref.current.continuousStart();
 },[])

 const profileGroupMutate = useMutation(Auth.newProfileGroup,{
     onSettled:((x)=>{
      ref.current.complete();
      refetch();
    }),
    onSuccess:((x)=>{
        const profileGroup = x.data.profileGroup;
        setState({});
        setProfileGroups([...profileGroups, ...profileGroup]);
        setNewProfileGroup(false);
        refetch();
    }),
    onError:((x)=>{
         setNewProfileGroup(false);
    })
   });

    const handleNewProfileGroup = () => {
        profileGroupMutate.mutate(state);
        ref.current.continuousStart();
    };

    const profileGroupEdit = useMutation(Auth.editProfileGroup,{
        onSettled:((x)=>{
         ref.current.complete();
       }),
       onSuccess:((x)=>{
           setState({});
           refetch();
           setNewProfileGroup(false);
           setEditProfileGroup(false);
       }),
       onError:((x)=>{
            setNewProfileGroup(false);
       })
      });
   
    const handleEditProfileGroup = () => {
            ref.current.continuousStart();
            console.log(state);
            profileGroupEdit.mutate(state);
       };

const newProfileGroupMember = useMutation(Auth.newProfileGroupMember,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
    const profiles = x.data.profileGroups;
    setEditProfileGroupMember(false);
    setProfileGroups(profiles);
    }),
    onError:(()=>{
      ref.current.complete();
    })

})

const deleteProfileGroupMember = useMutation(Auth.deleteProfileGroupMember,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
    setShowConfirmProfileGroupMemberDelete(false)
    refetch();
    }),
    onError:(()=>{
      ref.current.complete();
    })

})

const deleteProfileGroup = useMutation(Auth.deleteProfileGroup,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        setShowConfirmProfileGroupDelete(false)
        refetch();
    }),
    onError:(()=>{
      ref.current.complete();
    })

})

const addNewMember =(index,profileGroup) => {
    const newProfileGroup = {...profileGroup, addNew:true};
    const newProfileGroups = [...profileGroups];
    newProfileGroups[index] = newProfileGroup;
    setProfileGroups(newProfileGroups);
    setState({});
};

const editMember =(index,profileGroup) => {
    const newProfileGroup = {...profileGroup, addNew:true};
    const newProfileGroups = [...profileGroups];
    newProfileGroups[index] = newProfileGroup;
    setProfileGroups(newProfileGroups);
};

const handleCancelNewMember = (index,profileGroup) => {
    const newProfileGroup = {...profileGroup, addNew:false};
    const newProfileGroups = [...profileGroups];
    newProfileGroups[index] = newProfileGroup;
    setEditProfileGroupMember(false);
    setProfileGroups(newProfileGroups);
}

const handleSaveNewMember = (e,index,profileGroup) => {
    e.preventDefault();
    const formId = index+'-new-member-form';
    var formEl = document.forms[formId];
    var formData = new FormData(formEl);
    const firstName = formData.get('firstName');
    const username = formData.get('username');
    const lastName = formData.get('lastName');
    const email = formData.get('email');
    const phone = formData.get('phone');
    const nationalId = formData.get('nationalId');
    const DOB = formData.get('DOB');
    const nationalCountryOfIssue = formData.get('nationalCountryOfIssue');

    const newMember = { 
        firstName, 
        lastName,
        username,
        email,
        phone,
        nationalId,
        DOB,
        nationalCountryOfIssue,
        profileGroup:profileGroup._id,
    };


    var formDataSubmit = new FormData();
    for(var key in newMember){
        formDataSubmit.append(key, newMember[key]);
    }


    (state.proofOfId || []).map((file)=>{
        formDataSubmit.append("supportingDocuments", file);
    });

    console.log(formDataSubmit)

    newProfileGroupMember.mutate(formDataSubmit);
    
};

const saveEditProfileGroupMember = useMutation(Auth.editProfileGroupMember,{
    onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        refetch();
        setEditProfileGroupMember(false);
    }),
    onError:(()=>{
      ref.current.complete();
    })
})

const handleEditProfileGroupMember = (e) =>{
    e.preventDefault();
    ref.current.continuousStart();
    var formData = new FormData();
    for(var key in state){
        if(key == 'proofOfId '){
            (state.proofOfId || []).map((file)=>{
                formData.append("supportingDocuments", file);
            });
        }else{
            formData.append(key, state[key]);
        }
    }

    console.log(formData)

    saveEditProfileGroupMember.mutate(state);
}

const cancelProfileGroupCreation = () => {
    setNewProfileGroup(false);
    setEditProfileGroup(false);
    setState({});
}

const handleInputChange = (e)=>{
    setState({...state,[e.target.name]:e.target.value})
  };

const [showConfirmProfileGroupMemberDelete, setShowConfirmProfileGroupMemberDelete] = useState(false);
const [showConfirmConfirmProfileGroupDelete, setShowConfirmProfileGroupDelete] = useState(false);

const [id, setId] = useState("");
const [isValidNationalID, setIsValidNationalID] = useState(true)

  return (
   <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>

            <ConfirmDeleteProfileGroupMemberModal setShowConfirmProfileGroupMemberDelete ={setShowConfirmProfileGroupMemberDelete} deleteProfileGroupMember ={deleteProfileGroupMember} id={id} show = {showConfirmProfileGroupMemberDelete} onClose = {setShowConfirmProfileGroupMemberDelete}/>
            <ConfirmDeleteProfileGroupModal id={id} show = {showConfirmConfirmProfileGroupDelete} deleteProfileGroup={deleteProfileGroup} onClose = {setShowConfirmProfileGroupDelete}/>

            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container'>
                    <div style={{height:'100%'}}>
            <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
         <div className='settings-component' style={{minHeight:newProfileGroup?'50vh':'inherit'}}>

                <div className='settings-component-inner-right' >
                    <h2>Manage Profiles</h2>
                    <p>You can organise family members, people within your orginasation, church members and more from this 
                        tab. Create a group and add members to it, to start managing your profiles.
                    </p>
                    <button 
                    onClick={() => {setNewProfileGroup(true); setEditProfileGroup(false); }}
                    className='btn'>Create New Profile Group</button>
                </div>

                <div className='settings-component-inner-left'>

                    {
                        !state.loading && !newProfileGroup && profileGroups.length <= 0 && <div className='empty-profile-group'>
                            <FontAwesomeIcon icon={faUserFriends} size="3x" style={{color:'#ea9342'}}/>
                            <h3>No Profile Groups Available</h3>
                        </div>
                    }

                    { 
                    (newProfileGroup || editProfileGroup) &&
                        <>
                            <Input 
                            onChange={(e) => setState({...state, name:e.target.value})}
                            type="text" 
                            name="name"
                                value={state.name} placeholder="eg family, boys, girls, executives" 
                                label="Group Name"  
                                style={{marginRight:10}} 
                                edit
                                />
                                
                            <Input 
                            onChange={(e) => setState({...state, description:e.target.value})}
                            type="textarea" name="description" value={state.description} placeholder="eg my children" label="Group Short Description"  style={{marginRight:10}} />
                            <div>
                                <button 
                                onClick={(event)=>{
                                    if(editProfileGroup){
                                        handleEditProfileGroup(event);
                                    }else{
                                        handleNewProfileGroup(event)
                                    }
                                }} 
                                className='btn btn-primary' 
                                style={{width:100,marginTop:30,marginBottom:0,
                                marginRight:10,
                                backgroundColor:profileGroupMutate.isLoading ? '#ccc' : '#47878a',
                                }}
                                disabled={profileGroupMutate.isLoading}
                                >Save</button>
                                <button
                                onClick={()=>{
                                    cancelProfileGroupCreation()
                                }} 
                                className='btn btn-primary'
                                style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}
                                >Cancel</button>
                            </div>
                        </>
                    }

                   {
                        profileGroups.length > 0 &&  !(newProfileGroup || editProfileGroup) && <div>
                            <h2>Profile Groups</h2>
                            {profileGroups.map((profileGroup, index) => {
                                return <div className='profile-group' key={index}>
                                        <div className='flex-lg'>
                                            <FontAwesomeIcon icon={faUserFriends} size="2x" style={{color:'#ea9342'}}/>
                                            <h3>{profileGroup.name}</h3>
                                        </div>
                                        <div className='flex-lg'>
                                            <p className='p-h'>{profileGroup.description}</p>
                                                <div>
                                                    <FontAwesomeIcon size="2x" onClick={()=> {setState(profileGroup); setEditProfileGroup(true); setNewProfileGroup(false)}} icon={faSquarePen}  style={{color:'green',marginRight:10}}/>
                                                    <FontAwesomeIcon onClick={()=>{
                                                    setId(profileGroup._id);
                                                    setShowConfirmProfileGroupDelete(true);
                                                    }} icon={faDeleteLeft} size="2x" style={{color:'red',marginRight:10}}/>
                                                </div>
                                            
                                        </div>
                                    </div>
                            })}
                        </div>          
                     }
                </div>
            </div>
                     
            { 
                profileGroups.length > 0 &&  !(newProfileGroup || editProfileGroup) && profileGroups.map((profileGroup, index) => {

                    return <div className='settings-component bordered padding-right' key={index}>

                        <div className='settings-component-inner-right'>
                            <h2>{profileGroup.name}</h2>
                            <p>{profileGroup.description}</p>
                            <button 
                            onClick={() => {addNewMember(index, profileGroup); setIsValidNationalID(true)}}
                            className='btn' >Add New {profileGroup.name} Members</button>
                        </div>

                        <div className='settings-component-inner-left '>
                            { profileGroup?.members?.length <= 0 && !(profileGroup.addNew)? 
                                <div className='empty-profile-group'>
                                    <FontAwesomeIcon icon={faUserFriends} size="2x" style={{color:'#e0e0e0'}}/>
                                    <h3>No {profileGroup.name} Available</h3>
                                </div> 
                                    : !(profileGroup.addNew) && 
                                <div className='profile-groups-members-container ' style={{paddingLeft:20,paddingRight:20}}>
                                    <h3>Members</h3>
                                        {
                                            (profileGroup?.members || []).map((x,index_2)=>{
                                                return <div key={index_2} className='secondary-profile-member'
                                                style={{
                                                        display:'flex',
                                                        flexDirection:'row',
                                                        justifyContent:'space-between',
                                                        width:'100%',
                                                        border:'1px solid #eee',
                                                        padding:10,
                                                        alignItems:'center'
                                                }}
                                                >
                                                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                            <FontAwesomeIcon icon={faUserFriends} size="2x" style={{color:'#e0e0e0',marginRight:10}}/>
                                                            <div className='flex-lg'>
                                                                <div>
                                                                    <strong><p style={{margin:0}}>{x.username}</p></strong>
                                                                    <p style={{margin:0}}>{x.firstName} {x.lastName}</p>
                                                                    <p style={{margin:0}}>{x.email}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Link to = "#">
                                                                <FontAwesomeIcon  onClick={(event)=>{
                                                                    editMember(index, profileGroup);
                                                                    setEditProfileGroupMember(true); 
                                                                    setState(x);
                                                                    }
                                                                } icon={faSquarePen} size="2x" style={{color:'green',marginRight:10}}/>
                                                            </Link>
                                                            <FontAwesomeIcon onClick={()=>{
                                                            setId(x._id);
                                                            setShowConfirmProfileGroupMemberDelete(true);
                                                            }} icon={faDeleteLeft} size="2x" style={{color:'red', marginRight:10}}/>  
                                                        </div>  
                                                    </div>   
                                            })
                                        }
                                </div> 
                            } 
    
                            {
                                (profileGroup.addNew) && <div className='add-new-member-container'>
                                <form 
                                    onMouseEnter={()=>{
                                        setEnableUserQuery(false);
                                    }}
                                    onSubmit={(e)=>{
                                    e.preventDefault()
                                    if(editProfileGroupMember){
                                        handleEditProfileGroupMember(e);
                                    }else{
                                        handleSaveNewMember(e,index,profileGroup);
                                    }
                                    
                                }} id={index+"-new-member-form"}>
                                    <div className='flex-lg'>
                                        <Input 
                                        value = {state?.username}
                                        onChange = {handleInputChange}
                                        type="text" name="username" placeholder="" label="Secondary Profile Username"  style={{marginRight:10}}  edit/>

                                        <Input onChange = {handleInputChange} value = {state?.userType} type="select" name="userType" placeholder="" label="Secondary Profile Type" edit >
                                                {
                                                    AccountTypes.map((x,index)=>{
                                                        return <option key={index} value={x.value}>{x.name}</option>
                                                    })
                                                }
                                        </Input>
                                    </div>
                                    <div className='flex-lg'>
                                        <Input onChange = {handleInputChange} value = {state?.firstName} type="text" name="firstName" placeholder="" label="First Name"  style={{marginRight:10}} edit />
                                        <br></br>
                                        <Input onChange = {handleInputChange} value = {state?.lastName} type="text" name="lastName" placeholder="" label="Last Name"  edit/>
                                    </div>
                                    <div className='flex-lg'>
                                        <Input onChange = {handleInputChange} value = {state?.email} type="text" name="email" placeholder="" label="Email" style={{marginRight:10}} edit/>
                                        <Input onChange = {handleInputChange} value = {state?.phone} type="text" name="phone" placeholder="" label="Phone" edit />
                                    </div>
                                    <div className='flex-lg'>
                                        <Input 
                                        onChange = {handleInputChange} 
                                        
                                         value = {state?.nationalCountryOfIssue} type="select" 
                                        name="nationalCountryOfIssue"
                                        placeholder="Zimbabwe" label="ID or Passport Country Of Issue" style={{marginRight:10}} edit>
                                        {
                                                countries.map((country, index) => {
                                                    return <option key={index} value={country.name}>{country.name}</option>
                                                })
                                        }
                                        </Input>
                                        <Input

                                         onChange = {(e) => {
                                            handleInputChange(e);
                                            setIsValidNationalID(validateNationalZIMId(e.target.value) || validatePassportNumberZim(e.target.value) || validateIdNumber(e.target.value).valid);
                                        }}
                                        
                                         error = { state?.nationalId ? !isValidNationalID ? "Invalid National ID Number" : "" : "ID is required" }
                                         value = {state?.nationalId} type="text" name="nationalId" placeholder="Id " label="ID Or Passport Number"  edit />
                                    </div>
                                    <div className='flex-lg'>
                                        <Input onChange = {handleInputChange} value = {state?.DOB} type="date" name="DOB" label="Date of birth" style={{marginRight:10}} edit />
                                    </div>
{/* 
                                    <FileUploader2 
                                        onFileUpload={(x)=>{setState({...state, proofOfId : x})}}
                                        title="Proof of Identity (upload Id card or passport)"
                                        />  */}
                                    <button 
                                    type='submit'

                                    disabled = {!isValidNationalID ||  !state?.nationalId}
                                    className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,marginRight:10}}>Save</button>
                                    <button 
                                    onClick={()=>{
                                        handleCancelNewMember(index,profileGroup);
                                    }}
                                    className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                                </form>

                                </div>
                            }
                        </div>
                    </div>
                })
            }
         </div>
                    
                    </div>
            </div>
    </div>
    
  )
}

export default SecondaryAccounts

const ConfirmDeleteProfileGroupMemberModal = (props) => {
    return (
          <Modal
          style={{zIndex:10000}}
          show={props.show}
          onHide={props.onClose}
          size="l"
          >
            <Modal.Body>
              <div style={{ textAlign:"center"}}>
                <FontAwesomeIcon icon={faWarning} style={{color : "red", height : 30, width:30}}/>
                <h3>Are you sure what to delete ?</h3>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{width : "fit-content", height : 35, }} onClick={()=>props.setShowConfirmProfileGroupMemberDelete(false)} variant=''>Cancel Action</Button>
              <Button style={{width : "fit-content", height : 35, background : "red" }} onClick={()=>props.deleteProfileGroupMember.mutate(props.id)} variant='danger'>Delete Profile Group Member</Button>
            </Modal.Footer>
         </Modal>
    )
  }
  

  const ConfirmDeleteProfileGroupModal = (props) => {
    return (
          <Modal
          style={{zIndex:10000}}
          show={props.show}
          onHide={props.onClose}
          size="l"
          >
            <Modal.Body>
              <div style={{ textAlign:"center"}}>
                <FontAwesomeIcon icon={faWarning} style={{color : "red", height : 30, width:30}}/>
                <h3>Are you sure what to delete?</h3>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{width : "fit-content", height : 35, }} onClick={()=>props.setShowConfirmProfileGroupDelete(false)} variant=''>Cancel Action</Button>
              <Button style={{width : "fit-content", height : 35, background : "red" }} onClick={()=>props.deleteProfileGroup.mutate(props.id)} variant='danger'>Delete Profile Group</Button>
            </Modal.Footer>
         </Modal>
    )
  }