import React, {Component, useState} from "react";
// import '../../assets/assets/css/sass/style.scss'
import {Link} from "react-router-dom";
import AccountsService from "../../API/AccountsService";
import {getEntityCountry, getGlobalSettings, setEntityCountry, setLoggedInUser, storeWalletID} from "../../API/Cookies";
import {decoded, encoded} from "../../API/RouterHelpers";
import {SelectorConstants} from "./SelectorConstants";
import {useQuery} from "react-query";
import LoadingBox from "./functional-components/LoadingBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrip, faIdBadge, faLongArrowAltDown, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../Routes/Routes";
import TrustFundModal from "./TrustFundModal";
import FundWalletModal from "./FundWalletModal";
import {
    NotificationContainer, NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import logo from './logo-2.png';
import InsuranceModal from "../insurance/InsuranceModal";

let publicUrl = process.env.PUBLIC_URL + "/";

const country = getEntityCountry() || "South Africa";

const Navbar = () => {

    const [selected, setSelected] = React.useState(0);
    const [showModal, setShowModal] = useState(false);
    const [state,setState] = useState({});
    const [showWalletModal, setWalletModal] = useState(false);
    const {data: fundingAccount, isLoading} = useQuery("fundingAccount", () => AccountsService.getUserSuspenseAccount(country), {
        onSuccess: (x) => {
            const walletID = x?.data?.account?._id;
            const country = x?.data?.account?.accountCountry;
            storeWalletID(country, walletID);
        }
    });

    /* Open when someone clicks on the span element */
    function openNav() {
        document.getElementById("myNav").style.width = "100%";
    };

    /* Close when someone clicks on the "x" symbol inside the overlay */
    function closeNav() {
        document.getElementById("myNav").style.width = "0%";
    };

    function currencyFormat(num) {
        const moneyString = country === "Zimbabwe" ? "$ " : "ZAR "
        return moneyString + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }


    return (
        <div >
            <TrustFundModal
                show={showModal}
                onClose={() => setShowModal(!showModal)}
            />
            <FundWalletModal
                show={showWalletModal}
                onClose={() => setWalletModal(!showWalletModal)}
            />
            <NotificationContainer />
            <header className="ltn__header-area ltn__header-5   gradient-color-4---" style={{borderBottom: '1px solid #eee', backgroundColor: '#fcfcfc', width: '100%'}}>
                <div className="ltn__header-top-area d-none" style={{backgroundColor: '#334155'}}>
                    <div className="container-top-bar-menu">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="ltn__top-bar-menu">
                                    <ul>
                                        <li>
                                            <a style={{color: '#fff', marginLeft: 20, fontWeight: 400}}>
                                                Tsigiro Usekelo Profile Management
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="top-bar-right text-end">
                                    <div className="ltn__top-bar-menu">
                                        <ul>
                                            {/* <li>
                                                <a style={{color: '#ea9342', marginLeft: 20, fontWeight: 400}}>
                                                    Tsigiro Usekelo Trust Fund Inactive
                                                </a>
                                            </li> */}
                                            {/* <li onClick={() => {
                                                setShowModal(true);
                                            }}>
                                                <a
                                                    style={{
                                                        padding: 10,
                                                        fontWeight: 400,
                                                        margin: 10,
                                                        backgroundColor: '#47878a', width: "auto",
                                                        borderRadius: 20,
                                                        color: '#fff',
                                                        marginLeft: 10, height: '30px',
                                                        display: 'flex', alignItems: 'center', justifyContent: "center"
                                                    }}
                                                >Activate Trust Fund Membership
                                                </a>
                                            </li> */}
                                            <li 
                                            style={{padding:5,margin:5,height:35}}
                                            onClick={() => {
                                                setLoggedInUser();
                                                document.location.reload();
                                            }}>
                                                <a href="#" >
                                                    <span style={{color: "#fff", fontWeight: 400, textAlign: 'center'}}>
                                                        <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 10}} />
                                                        logout
                                                    </span>

                                                </a>
                                            </li>
                                            <li>
                                                {/* <Social /> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="ltn__header-middle-area ltn__header-sticky  ltn__sticky-bg-white">
                    <div className="container-navbar">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo go-top">
                                        <Link to="/">
                                            <img
                                                src={logo}
                                                style={{width: "250px"}}
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column" style={{width: '100%'}}>
                                <div className="header-menu d-none d-xl-block">
                                    <nav>
                                        <div className="ltn__main-menu go-top ">
                                            <ul >
                                                <li>
                                                    <Link to="/personal-details" style={{fontWeight: 500}}>Personal</Link>
                                                </li>
                                                <li>
                                                    <Link to="/secondary-profiles" style={{fontWeight: 500}}>Secondary Profiles</Link>
                                                </li>
                                                <li>
                                                    <Link to="/billing-and-banking" style={{fontWeight: 500}}>Billing and Banking</Link>
                                                </li>
                                                <li >
                                                    <Link to="/notifications" style={{fontWeight: 500}}>Notifications</Link>
                                                </li>
                                                <li>
                                                    <Link to="/beneficiaries" style={{fontWeight: 500}}>Beneficiaries</Link>
                                                </li>
                                                <li>
                                                    <Link to="/privacy-and-data" style={{fontWeight: 500}}>Privacy and Data</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                                <div className="ltn__drop-menu user-menu">
                                    <ul>
                                        <li style={{minWidth: '130px', width: 'auto'}}>
                                            {
                                                isLoading ? <LoadingBox /> : <Link to="#" style={{width: 'auto', fontSize: 13, fontWeight: 500, paddingRight: 10, paddingLeft: 10}}>
                                                    {currencyFormat(fundingAccount?.data?.account?.balance || 0)}
                                                </Link>
                                            }

                                        </li>
                                    </ul>
                                </div>
                                <div className="ltn__drop-menu user-menu">
                                    <ul>
                                        <li>
                                            <Link to="#" style={{width: '90px', fontSize: 12, display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontWeight: 500}}>
                                                Apps <i className="dripicons-view-apps" style={{fontSize: 20, marginTop: -3}} ></i>
                                            </Link>
                                            <ul
                                                className="go-top tsigiro-app-container"
                                                style={{display: 'grid', flexDirection: 'row', height: 'auto', width: 'auto', minWidth: 300, gridTemplateColumns: '1fr 1fr', gridGap: '1rem', padding: 5}}
                                            >
                                                {
                                                    SelectorConstants.map((x, index) => {
                                                        return <li
                                                            key={index}
                                                            onClick={() => {
                                                              !x.active ?
                                                                    NotificationManager.warning(
                                                                        <h1 style={{color: '#fff', fontSize: 13, textAlign: 'left'}}>{x.title + ` module not yet active,you'll be notified on email once active`}</h1>,
                                                                        // <p style={{color: '#fff', fontSize: 12}}>{x.title + ` Module`}</p>,
                                                                    ) : x.redirectURL();
                                                                // if(x.title === 'Funeral Cover'){
                                                                //     setState({show:true});
                                                                // }else{
                                                                //   !x.active ?
                                                                //     NotificationManager.warning(
                                                                //         <h1 style={{color: '#fff', fontSize: 13, textAlign: 'left'}}>{x.title + ` module not yet active,you'll be notified on email once active`}</h1>,
                                                                //         // <p style={{color: '#fff', fontSize: 12}}>{x.title + ` Module`}</p>,
                                                                //     ) : x.redirectURL();
                                                                // }
                                                            }
                                                            }
                                                            style={{color: '#000', cursor: 'pointer', textAlign: 'center', padding: 20}}>
                                                            <i className={x.icon} style={{color: '#47878a', fontSize: 18}}></i>
                                                            {/* <img src={x.image} style={{width: 30, height: 30}} /> */}
                                                            <p className='hvr-link' style={{margin: 0, fontSize: 13, fontFamily: 'Montserrat', fontWeight: 500}}>{x.title}
                                                            </p>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <InsuranceModal
                                show={state.show}
                                onClose={()=>setState({show:false})}
                                />
                                <div className="ltn__drop-menu user-menu" onClick={() => setWalletModal(true)}>
                                    <ul>
                                        <li>
                                            <Link to="#" style={{width: '100px', fontSize: 12, fontWeight: 500}}>
                                                Fund Wallet
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="ltn__drop-menu mobile-menu-toggle d-xl-none user-menu" style={{cursor: 'pointer'}} onClick={openNav}>
                                    <ul>
                                        <li>
                                            <i className="dripicons-menu" style={{fontSize: 20, marginTop: -3}} ></i>
                                        </li>
                                    </ul>
                                    {/* <a
                                        href="#"
                                        className="ltn__utilize-toggle"
                                    >
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id="myNav" className="overlay">
                    <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
                    <div className="overlay-content">
                        {
                            Routes.map((menu, index) => {
                                if (menu.isPrivate)
                                    return (
                                        <div className='single-selection' onClick={() => {
                                            setSelected(index);
                                            closeNav();
                                        }}>
                                            <a href={menu.path} style={{color: window.location.pathname === menu.path ? "#fff" : "#000", fontSize: window.location.pathname === menu.path ? 23 : 18, fontWeight: 400}}>{menu.name}</a>
                                            {window.location.pathname === menu.path && <hr style={{height: '1px', marginBottom: -3, backgroundColor: '#fff', color: '#fff'}}></hr>}
                                        </div>
                                    )
                            })
                        }
                    </div>
                </div>
            </header >
            <div
                id="ltn__utilize-mobile-menu"
                className="ltn__utilize ltn__utilize-mobile-menu"
            >
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <Link to="/">
                                <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                            </Link>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li>
                                <Link to="/properties">Property Sales</Link>
                            </li>
                            <li>
                                <Link to="/investing">Property Investments</Link>
                            </li>
                            <li>
                                <Link to="/property-requests">My Requests</Link>
                            </li>
                            <li>
                                <Link to="/property-purchases">My Purchases</Link>
                            </li>
                            {/* <li >
                                    <Link to="/property-investments">My Investments</Link>
                                </li> */}
                            {/* <li>
                                <Link to="/contact">Contact Us</Link>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="ltn__utilize-menu-search-form">
              <form action={"#"}>
                <input type="text" placeholder="Search..." />
                <button>
                  <i className="fas fa-search" />
                </button>
              </form>
            </div>
            <div className="ltn__utilize-menu">
              <ul>
                <li>
                  <a href="#">Home</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/">Home Style 01</Link>
                    </li>
                    <li>
                      <Link to="/home-v2">Home Style 02</Link>
                    </li>
                    <li>
                      <Link to="/home-v3">Home Style 03</Link>
                    </li>
                    <li>
                      <Link to="/home-v4">Home Style 04</Link>
                    </li>
                    <li>
                      <Link to="/home-v5">
                        Home Style 05{" "}
                        <span className="menu-item-badge">video</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-v6">Home Style 06</Link>
                    </li>
                    <li>
                      <Link to="/home-v7">Home Style 07</Link>
                    </li>
                    <li>
                      <Link to="/home-v8">Home Style 08</Link>
                    </li>
                    <li>
                      <Link to="/home-v9">Home Style 09</Link>
                    </li>
                    <li>
                      <Link to="/home-v10">Home Style 10</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/about">About</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Service Details</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-v2">Portfolio - 02</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/team-details">Team Details</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/location">Google Map Locations</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/shop">Shop</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link to="/shop-grid">Shop Grid</Link>
                    </li>
                    <li>
                      <Link to="/shop-left-sidebar">Shop Left sidebar</Link>
                    </li>
                    <li>
                      <Link to="/shop-right-sidebar">Shop Right sidebar</Link>
                    </li>
                    <li>
                      <Link to="/product-details">Shop Details</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/checkout">Checkout</Link>
                    </li>
                    <li>
                      <Link to="/my-account">My Account</Link>
                    </li>
                    <li>
                      <Link to="/login">Sign in</Link>
                    </li>
                    <li>
                      <Link to="/register">Register</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/blog-grid">News</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/blog">News</Link>
                    </li>
                    <li>
                      <Link to="/blog-grid">News Grid</Link>
                    </li>
                    <li>
                      <Link to="/blog-left-sidebar">News Left sidebar</Link>
                    </li>
                    <li>
                      <Link to="/blog-right-sidebar">News Right sidebar</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">News details</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Service Details</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-2">Portfolio - 02</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/team-details">Team Details</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/history">History</Link>
                    </li>
                    <li>
                      <Link to="/add-listing">Add Listing</Link>
                    </li>
                    <li>
                      <Link to="/locations">Google Map Locations</Link>
                    </li>
                    <li>
                      <Link to="/404">404</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/coming-soon">Coming Soon</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div> */}
                    {/* <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <Link to="/my-account" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user" />
                    </span>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to="/wishlist" title="Wishlist">
                    <span className="utilize-btn-icon">
                      <i className="far fa-heart" />
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </Link>
                </li>
                <li>
                  <Link to="/cart" title="Shoping Cart">
                    <span className="utilize-btn-icon">
                      <i className="fas fa-shopping-cart" />
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </Link>
                </li>
              </ul>
            </div> */}
                    {/* <div className="ltn__social-media-2">
              <ul>
                <li>
                  <a href="#" title="Facebook">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Twitter">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Instagram">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div> */}
                </div>
            </div>

            {/* Utilize Cart Menu Start */}

        </div >
    );
}

export default Navbar;
