import React,{ useState ,useRef,useEffect} from 'react';
import {useMutation, useQuery} from 'react-query';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import LoadingBar from 'react-top-loading-bar';
import Auth from '../../../API/Auth';
import Navbar from '../../NavBar/NavBar';
import success from '../../../assets/success.gif';

const PaymentRedirect = () => {

   const [notifications, setNotifications] = useState([]);
   
   const ref = useRef(null);

    useQuery("notifications",Auth.fetchNotifications,{
        onSuccess:((x)=>{
            const notifies  = x.data.usernotifications;
            setNotifications([...notifies]);
        }),
        onSettled:((x)=>{
         ref.current.complete();
        })
    });

     useEffect((x)=>{
            ref.current.continuousStart();
    },[]);

    const notificationMutation = useMutation(Auth.updateNotification,{
    onSuccess:((x)=>{
         ref.current.complete();
    }),
    onError:((x)=>{

    }),
    onSettled:((x)=>{
         ref.current.complete();
    })
    });

    const onToggle = (id, option)=>{
            ref.current.continuousStart();
            notificationMutation.mutate(id);
    }

  return (
    <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container' >
                    <div>
            <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
            <div className='settings-component' style={{borderBottom:'none',height:'40vh'}}>
            <div className='settings-component-inner-right' style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderBottom:'none'}}>
                 <img src={success} alt="" style={{width:350}} />
                <h2 style={{textAlign:'center'}}>Payment not yet completed </h2>
                <p>we'll update your wallet once we've received your payment</p>
            </div>
            </div>
            </div>
                </div>
            </div>
    </div>
    
  )
};

export default PaymentRedirect;