export const AccountTypes = [
    {
        name: 'Individual',
        value: 'individual',
        description:'An individual account type, allows you to manage your investments account between yourself and family members'

    },
    {
        name: 'Corporate',
        value: 'corporate',
        requiresName: true,
    },
    {
        name: 'Trust',
        requiresName: true,
        value: 'trust'
    },
    {
        name: 'Foundation',
        value: 'foundation',
        requiresName: true,

    },
    {
        name: 'Partnership',
        value: 'partnership'
    },
    {
        name: 'Charity',
        value: 'charity',
        requiresName: true,

    },
    {
        name: 'Sole Proprietor',
        value: 'sole proprietor',
        requiresName: true,

    },
    {
        name:'Church',
        value: 'church',
        requiresName: true,

    },
    {
        name: 'Other',
        value: 'other',
        requiresName:true
    }
]