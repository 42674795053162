import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faSquarePen, faUserFriends, faWarning } from '@fortawesome/free-solid-svg-icons';
import {countries} from '../../contants/countries';
import Input from '../../Inputs/Input';
import {useMutation, useQuery} from 'react-query';
import Auth from '../../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import Navbar from '../../NavBar/NavBar';
import { validateNationalZIMId, validatePassportNumberZim } from './Personal';
import { validateIdNumber } from 'south-african-id-validator';


const Beneficiaries = () => {
  
  const [addBeneficiary, setAddBeneficiary] = React.useState(false);
  const [editBeneficiary, setEditBeneficiary] = React.useState(false);
  const [edit,setEdit] = useState(true);
  const [beneficiary,setBeneficiary] = useState({});
  const [beneficiaries, setBeneficiaries] = React.useState([]);
  const [state,setState] = useState({});
  const ref = useRef(null);

  const add = useMutation(Auth.newBeneficiariesDetails,{
    onSuccess:(x)=>{
        ref.current.complete();
        setBeneficiaries([...beneficiaries, x.data.beneficiary]);
         setAddBeneficiary(false);
    },
    onSettled:()=>{
        ref.current.complete();
    }
  });

  const saveUpdates = useMutation(Auth.updateBeneficiariesDetails, {
    onSuccess:(x)=>{
        ref.current.complete();
        setEditBeneficiary(false);
    },
    onSettled:()=>{
        ref.current.complete();
        refetch();
    }
  });

  const deleteBeneficiary = useMutation(Auth.deleteBeneficiariesDetails, {
    onSuccess:(x)=>{
        ref.current.complete();
    },
    onSettled:()=>{
        ref.current.complete();
        setShowConfirmDelete(false);
        refetch();
    }
  });

  

  const save = (e) =>{
        e.preventDefault();
        ref.current.continuousStart()
        if(!editBeneficiary){
          add.mutate(state);
        }else{
          saveUpdates.mutate(state);
        }
  };

  const {refetch} = useQuery("beneficiaries",()=> Auth.getBeneficiariesDetails(),{
        onSuccess:(x)=>{
            setBeneficiaries(x.data.beneficiaries)
        },
        onSettled:()=>{
            ref.current.complete();
    }
  });

  const handleInputChange = (e)=>{
    setState({...state,[e.target.name]:e.target.value})
  };

  useEffect(()=>{
     ref.current.continuousStart();
  },[])

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [inValidAllocation, setInValidAllocation] = useState(true);
  const [beneficiaryId, setBeneficiaryId] = useState(null)
  const [isValidNationalID, setIsValidNationalID] = useState(true)

  
  return (
    <div style={{backgroundColor:'#fcfcfg'}}>
      <ConfirmDeleteModal beneficiaryId={beneficiaryId} show = {showConfirmDelete} setShowConfirmDelete = {setShowConfirmDelete} deleteBeneficiary = {deleteBeneficiary} onClose = {setShowConfirmDelete}/>
      <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
                <div className='selector-container'>
                  <div id='scroll-top'>
        <LoadingBar ref={ref} color="green" progress={5}  />
      <div className='settings-component' style={{borderBottom:'none',width:'100%'}}>
        <div className='settings-component-inner-right' style={{borderBottom:'none'}}>
          <h2>Beneficiaries</h2>
          <p>Manage your beneficiaries, so our team is able to reach out to members in the case of any unfortunate event
            we will require all beneficiaries to be verified before we can proceed with any payout</p>
            <button 
            onClick={() => {
              setState({}); 
              setEditBeneficiary(false);
              setAddBeneficiary(!addBeneficiary)
            }}
            className='btn btn-primary'>Add Beneficiary</button>
          </div>
          <div className='settings-component-inner-left'>
            { beneficiaries.length <= 0 && !addBeneficiary && <div className='empty-profile-group'>
                        <FontAwesomeIcon icon={faUserFriends} size="3x" style={{color:'#e0e0e0'}}/>
                        <h3>No Active Beneficiaries</h3>
            </div>}
            {
              (addBeneficiary || editBeneficiary) && <div className='form-group' >
                <form onSubmit={save}>
                    <div className='flex-lg'>
                      <Input 
                      edit={edit}
                      type="text" 
                      error={!state?.firstName && "required"}
                      onChange={handleInputChange} value={state?.firstName} name="firstName" placeholder="" label="First Name"   />
                      <br></br>
                      <Input 
                      edit={edit}
                      error={!state?.lastName && "required"}
                      type="text" onChange={handleInputChange} value={state?.lastName} name="lastName" placeholder="" label="Last Name"  />
                      </div>
                      <div className='flex-lg'>
                      <Input 
                      edit={edit}
                      type="text" onChange={handleInputChange} value={state?.email} name="email" placeholder="" label="Email"/>
                      <Input 
                      edit={edit}
                      type="text" onChange={handleInputChange} value={state?.phone} name="phone" placeholder="" label="Phone"  />
                      </div>
                      <div className='flex-lg'>
                      <Input
                       edit={edit}
                       type="date" onChange={handleInputChange} value={ editBeneficiary? moment(state?.dateOfBirth).format("YYYY-MM-DD") : undefined } name="dateOfBirth" label="Date of birth" style={{marginRight:10}} />
                      <Input 
                      edit={edit}
                      type="text"  
                      onChange = {(e) => {
                          handleInputChange(e);
                          setIsValidNationalID(validateNationalZIMId(e.target.value) || validatePassportNumberZim(e.target.value) || validateIdNumber(e.target.value).valid);
                      }}
                    
                      error = { state?.nationalId ? !isValidNationalID ? "Invalid National ID Number" : "" : "ID is required" }
                      value={state?.nationalId} name="nationalId" placeholder="" label="ID Number"  />
                      </div>
                      <div className='flex-lg'>
                          <Input 
                          edit={edit}
                          type="select" onChange={handleInputChange} value={state?.countryOfIssue}  name="countryOfIssue" placeholder="Zimbabwe" label="ID Country of issue" style={{marginRight:10}}>
                          {
                                  countries.map((country, index) => {
                                      return <option key={index} value={country.name}>{country.name}</option>
                                  })
                          }
                          </Input>
                        <Input 
                        edit={edit}
                        type="number" 
                        // error={
                        //   !state?.allocationPercentage? "required" :
                        //   beneficiaries.filter(x=>x._id != state?._id).reduce((total, ben)=>{return Number(total) + Number(ben.allocationPercentage)}, 0) + Number(state?.allocationPercentage)> 100?
                        //   "Allocations must add up to 100%"
                        //   :
                        //   ""
                        // }
                        id="allocationPercentage"
                        tooltip="Distribution percentage on disbursement"
                        onChange={async (e)=>{
                            handleInputChange(e);
                          }} value={state?.allocationPercentage}  name="allocationPercentage" 
                          placeholder="" 
                          label="Percentage Holdings"  
                        />
                      </div>
                      <button 
                      type='submit'
                      disabled={

                        // beneficiaries.filter(x=>x._id != state?._id).reduce((total, ben)=>{return Number(total) + Number(ben.allocationPercentage)}, 0) + Number(state?.allocationPercentage)> 100 ||
                        !state?.allocationPercentage || !state?.firstName || !state?.lastName || 
                        !isValidNationalID ||  !state?.nationalId
                      }
                      loading = {true}
                      className='btn btn-primary' onClick={save} style={{width:100,marginTop:30,marginBottom:0,marginRight:10,
                      backgroundColor:!state?.allocationPercentage || !state?.firstName || !state?.lastName ? '#ccc' : '#47878a'}}>Save</button>
                      <button 
                      onClick={()=> {
                            setAddBeneficiary(false);
                            if(editBeneficiary){
                              setState({});
                              setEditBeneficiary(false);
                            }

                      } }
                      className='btn btn-primary' style={{width:100,marginTop:30,marginBottom:0,backgroundColor:'#ea9342'}}>Cancel</button>
                </form>
                </div>
            }
          </div>
       </div>

       <div className='profile-groups-members-container ' style={{paddingLeft:20,paddingRight:20}}>
        {/* <h3>Members</h3> */}
            {
                (beneficiaries || []).map((x,index)=>{
                    return <div key={index} className='secondary-profile-member'
                    style={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'space-between',
                            width:'100%',
                            border:'1px solid #eee',
                            padding:10,
                            alignItems:'center',
                            marginBottom:20
                    }}
                    >
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <FontAwesomeIcon icon={faUserFriends} size="2x" style={{color:'#e0e0e0',marginRight:10}}/>
                                <div className='flex-lg'>
                                    <div>
                                        <strong><p style={{margin:0}}>{x.username}</p></strong>
                                        <p style={{margin:0}}>{x.firstName} {x.lastName}</p>
                                        <p style={{margin:0}}>{x.email}</p>
                                    </div>
                                </div>
                            </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <p style={{margin:10}}>{x.allocationPercentage}%</p>
                                    <Link to={"#scroll-top"}>
                                      <FontAwesomeIcon onClick={()=>{setState(x); setEditBeneficiary(true); setAddBeneficiary(false)}} icon={faSquarePen} size="2x" style={{color:'green',marginRight:10}}/>
                                    </Link>
                                    <Link to={"#"}>
                                      <FontAwesomeIcon onClick={()=>{
                                          setBeneficiaryId(x._id);
                                          setShowConfirmDelete(true);
                                        }} icon={faDeleteLeft} size="2x" style={{color:'red',marginRight:10}}/>
                                    </Link>
                                </div>
                        </div>   
                })
            }
    </div> 
    </div>

                </div>
            </div>
    </div>
    
  )
}

export default Beneficiaries;




const ConfirmDeleteModal = (props) => {
  return (
        <Modal
        style={{zIndex:10000}}
        show={props.show}
        onHide={props.onClose}
        size="l"
        >
          <Modal.Body>
            <div style={{ textAlign:"center"}}>
              <FontAwesomeIcon icon={faWarning} style={{color : "red", height : 30, width:30}}/>
              <h3>Are you sure what to delete?</h3>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width : "fit-content", height : 35, }} onClick={()=>props.setShowConfirmDelete(false)} variant=''>Cancel Action</Button>
            <Button style={{width : "fit-content", height : 35, background : "red" }} onClick={()=>props.deleteBeneficiary.mutate(props.beneficiaryId)} variant='danger'>Delete Beneficiary</Button>
          </Modal.Footer>
       </Modal>
  )
}