import React,{ useState ,useRef } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Input from '../Inputs/Input';
import Logo from '../../assets/logo.png';
import ReactCodeInput from 'react-verification-code-input';
import LoadingBar from 'react-top-loading-bar';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import {
    NotificationContainer, NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const OTPFORGOT = (props) => {

  const email = props.location.search.split('?')[1];

  const [state,setState] = useState({email:email });

  const ref = useRef(null);

  const mutation = useMutation(Auth.PasswordOTP, {
        onSuccess: (s) => {
            setState({...state,redirectLogin:true,id:s.data.id});
        },

        onSettled: () => {
            ref.current.complete();
        }
  });

  const resendCode = useMutation(Auth.ForgotPassword, {
    onSuccess: (s) => {
             NotificationManager.success(
              `please allow upto 30 seconds to receive OTP.`,
              "OTP resent successfully",
            );
        },
    onSettled: () => {
            ref.current.complete();
     },
  });

   const resendOTP = ()=>{
    resendCode.mutate(state);
    ref.current.continuousStart()
  }

  

  const submitOTP = () =>{
    mutation.mutate(state);
    ref.current.continuousStart()
  };    

  return (
    <div className='container'>
      {/* <h3 style={{color:'#fff'}}>Explore Investment Opportunities with Tsigiro Today</h3> */}
      <div className='inner-container-register-otp'>
        {
           state.redirectLogin && <Redirect to={`/forgot-password-finisher?${state.email}`} />
        }
        <NotificationContainer/>
        <LoadingBar color='green' progress={5} ref={ref} />
        <div className='overlay-inner-container-otp'>
                <img  src={Logo} className='logo-md'/>
                <h3>Enter Password Reset Code</h3>
                <h4>we sent a 6 digit code to your email, enter it below</h4>
                {
                  mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
                }
                <ReactCodeInput 
                className='otp-input'
                inputStyle={{fontFamily:'Montserrat-Regular',color:'green'}}
                onChange={(e)=>{
                  setState({...state,otpcode:e})
                }}/>
                 <button 
                 disabled={mutation.isLoading || !state.otpcode}
                 style={{backgroundColor:mutation.isLoading || state?.otpcode?.length < 6 || !state?.otpcode ? '#ccc' : '#47878a'}}
                 onClick={submitOTP}
                 className='btn btn-primary'>Verify</button>
         <p style={{cursor:'pointer'}}>Didn't receive code ? <span onClick={resendOTP} style={{textDecoration:'underline',color:'green',cursor:'pointer'}}>Resend</span></p>            
        </div>
      </div>

         <div style={{position:'absolute',bottom:50,display:'flex',marginTop:150,width:400,justifyContent:'space-between',color:'#b9b9b9'}}>
                    <a>Terms of Service</a>
                    <a>Privacy</a>
                    <a>Cookies</a>
                    <a>Cookie Preferances</a>
          </div>
    </div>
  )
}

export default OTPFORGOT