import React from "react";
import ForgotPassword from "../ForgotPass/ForgotPass";
import ForgotPassFinisher from "../ForgotPassFinisher/ForgotPassFinisher";
import Home from "../Home/Home";
import Beneficiaries from "../Home/SettingComponents/Beneficiaries";
import BillingAndBanking from "../Home/SettingComponents/BillingAndBanking";
import Notifications from "../Home/SettingComponents/Notifications";
import PaymentFailed from "../Home/SettingComponents/PaymentFailed";
import PaymentRedirect from "../Home/SettingComponents/PaymentRedirect";
import PaymentSuccess from "../Home/SettingComponents/PaymentSuccess";
import Personal from "../Home/SettingComponents/Personal";
import PrivacyAndData from "../Home/SettingComponents/PrivacyAndData";
import SecondaryAccounts from "../Home/SettingComponents/SecondaryAccounts";
import Login from "../Login/Login";
import OTP from "../OTP/OTP";
import OTPFORGOT from "../OTPFORGOT/OTPFORGOT";
import Registration from "../Registration/Registration";
import successRegistration from "../Registration/SuccessRegistration";
import AccountVerification from "../Invest/AccountVerification";
import InvestmentComplete from "../Invest/InvestmentComplete";
import InvestmentFailed from "../Invest/InvestmentFailed";


const loginComponent = Login;
const registrationComponent = Registration;
const EMAIL_OTP = OTP;
const Home_PAGE = Home;

export const Routes = [
  {
    path: "/",
    component: loginComponent,
    exact:true
  },
  {
    path: "/login",
    component: loginComponent,
    exact:true,
  },
 {
    path: "/forgot-password",
    component: ForgotPassword,
    exact:true,
  },
   {
    path: "/otp-forgot-password",
    component: OTPFORGOT,
    exact:true,
  },
   {
    path: "/forgot-password-finisher",
    component: ForgotPassFinisher,
    exact:true,
  },
  {
    path: "/registration/success",
    component: successRegistration,
    exact:true
  },
  {
    path: "/registration",
    component: registrationComponent,
    exact:true
  },
  {
    path:"/investment-verification",
    component:AccountVerification,
    exact:true,
    bothAccess:true
  },
  {
    path:"/investment-complete",
    component:InvestmentComplete,
    exact:true,
    bothAccess:true
  },
  {
    path:"/investment-failed",
    component:InvestmentFailed,
    exact:true,
    bothAccess:true
  },
  {
    path: "/email-otp",
    component: EMAIL_OTP,
    exact:true
  },
  {
    path: "/home",
    component: Home_PAGE,
    exact:true,
    isPrivate:true
  },
  {
    path: '/personal-details',
    component: Personal,
    name:'Personal',
    exact:true,
    isPrivate:true
  },
  {
    path: '/secondary-profiles',
    component: SecondaryAccounts,
    name:'Secondary Profiles',
    exact:true,
    isPrivate:true
  },
   {
    path: '/billing-and-banking',
    component: BillingAndBanking,
    name:'Billing and Banking',
    exact:true,
    isPrivate:true
  },
  {
    path: '/notifications',
    component: Notifications,
    name:'Notifications',
    exact:true,
    isPrivate:true
  },
  {
    path: '/payment-success',
    component: PaymentSuccess,
    name:'Payment Success',
    exact:true,
    isPrivate:true
  },
  {
    path: '/payment-failed',
    component: PaymentFailed,
    name:'Payment Failed',
    exact:true,
    isPrivate:true
  },
  {
    path: '/payment-redirect',
    component: PaymentRedirect,
    name:'Payment Redirect',
    exact:true,
    isPrivate:true
  },
  {
    path: '/beneficiaries',
    component: Beneficiaries,
    name:'Beneficiaries',
    exact:true,
    isPrivate:true
  },
   {
    path: '/privacy-and-data',
    component: PrivacyAndData,
    name:'Privacy and Data',
    exact:true,
    isPrivate:true
  },

]