import React, { useRef, useState } from 'react'
import Toggle from 'react-toggle';
import Navbar from '../../NavBar/NavBar';
import LoadingBar from 'react-top-loading-bar';
import { useMutation, useQuery } from 'react-query';
import Auth from '../../../API/Auth';

const PrivacyAndData = () => {
  const ref = useRef(null);
  const [state,setState] = React.useState({privacyAndDataPolicyAccepted : false});

    useQuery("personalDetails",Auth.Me,{
    onSettled:((x)=>{
      ref.current.complete();
    }),

    onSuccess:((x)=>{
        const user = x.data.user;
        setState(user);
    }),
  });

  const personalMutate = useMutation(Auth.UpdatePersonalDetails,{
      onSettled:((x)=>{
      ref.current.complete();
    }),
    onSuccess:((x)=>{
        const user = x.data.user;
        setState(user);
    }),
    onError:((x)=>{
    })
  });


  return (
    <div style={{backgroundColor:'#fcfcfg'}}>
            <Navbar/>
            <div className='container' style={{backgroundColor:'transparent',height:'100%'}}>
              <LoadingBar color='green' progress={5} ref={ref} style={{height:5}} />
                <div className='selector-container'>
                    <div>
                        <div className='settings-component'>
                            <div className='settings-component-inner-right' style={{width:'100%'}}>
                              <h2>Privacy & Data</h2>
                              <p>
                                Our Team at Tsigiro are there to ensure the safest and correct use your data
                                and we are committed to protecting your privacy. We will only use the information that we collect about you lawfully
                                in accordance with the Data Protection Acts of your country of residence. <br></br><br></br>
                                To manage how we use your data please use this tab to control your privacy settings
                                </p>
                            </div>
                          </div>
                          <div className='settings-component'>
                            <div className='settings-component-inner-right ' style={{width:'100%'}} >
                              <div className='flex-items'>
                                <h2>Information Sharing</h2>
                              <div>
                                <Toggle onChange={(e)=>{
                                  const value = !state?.privacyAndDataPolicyAccepted;
                                    setState({...state, privacyAndDataPolicyAccepted :   value});
                                    personalMutate.mutate({...state, privacyAndDataPolicyAccepted : value });
                                  }} 
                                  checked= {state?.privacyAndDataPolicyAccepted}
                                  />
                              </div>
                              </div>
                              
                              <p>To provide the best of your experience, we share your data accross our group of companies, which include 
                                Tsigiro, Diaspora Kapita, Tribetron and 21st Life Insurance. We also share your data with our partners and service providers to help us provide our services to you.
                              </p>
                            </div>
                          </div>
                      </div>
                </div>
            </div>
    </div>
  
  )
}

export default PrivacyAndData;