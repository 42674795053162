import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import '../src/assets/icons/_dripicons.scss';
import Login from './components/Login/Login';
import {MemoryRouter, Switch, Route, BrowserRouter, Redirect, HashRouter} from 'react-router-dom';
import Registration from './components/Registration/Registration';
import {Routes} from './components/Routes/Routes';
import './components/styles/main.css';
import './components/styles/responsive.css';
import './components/styles/account-verification.css';
import {getGlobalSettings, getLoggedInUser, isUserAuthenticated, setLoggedInUser} from './API/Cookies';
import {decoded, encoded} from './API/RouterHelpers';
import "react-tooltip/dist/react-tooltip.css";
import { useIdleTimer } from 'react-idle-timer';

function RouteWithSubRoutes(route) {
    if (!isUserAuthenticated() || route.bothAccess ) {
        return (
            <Route
                path={route.path}
                render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes} exact={route.exact} />
                )}
            />
        );
    } else {
        return (
            <Redirect
                to="/personal-details"
            />
        );
    }
};

const Timeout = getGlobalSettings()?.Timeout

function ProtectedRouteWithSubRoutes(route) {

    const [sessionIdle, setSessionIdle] = React.useState(false);
   
	const {
        start
      } = useIdleTimer({
        timeout: Timeout || 600000,
        onAction: () => { setSessionIdle(false);},
        debounce: 500,
        onIdle: () => {
                setSessionIdle(true);
                alert("You are idle system will now automatically log you out");
                setLoggedInUser();
                document.location.reload();
             },
        stopOnIdle: true,
        startManually: true,
      })
      useEffect(() => {
        start();
    }, [])

    if (isUserAuthenticated()) {
        return (
            <Route
                path={route.path}
                render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes} exact={route.exact} />
                )}
            />
        );
    } else {
        return (
            <Redirect
                to="/"
            />
        );
    }
};

function App() {

    useEffect(() => {
        fetch(window.location.origin)
        .then(response => response)
        .then(data => console.log(data));

        const fromUrl = window.location.href.split('?')[1];
        const urlEncodedAuth = fromUrl ? decoded(fromUrl) || "" : "";
        if (urlEncodedAuth.includes("logout")) {
            setLoggedInUser();
            window.location.href = "/";
        };

        if(isUserAuthenticated()){
            if(urlEncodedAuth.match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/)){
                const validAuth = JSON.stringify(getLoggedInUser());
                const urlEncodedKIT = encoded(validAuth);
                const newDecoded = decoded(fromUrl.replace("#/personal-details",""));

                window.location.href = newDecoded +"?"+ urlEncodedKIT;
            }
        };
        
    }, []);

    return ( 
        <HashRouter>
            <Switch>
                {Routes.map((route, i) => (
                    !route.isPrivate ?
                        <RouteWithSubRoutes
                            key={i}
                            {...route}
                        /> :
                        <ProtectedRouteWithSubRoutes
                            key={i}
                            {...route} />
                ))}
            </Switch>
        </HashRouter>
    );
}



export default App;
