import React,{ useRef, useState } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Agrostrong from '../../assets/agrostrong.png';
import Input from '../Inputs/Input';
import {decoded, encoded} from '../../API/RouterHelpers';
import {encryptInTransit} from '../../API/EncryptInTransit';
import {getDecodedUser, setEntityCountry, setGlobalSettings, setLoggedInUser} from '../../API/Cookies';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import LoadingBar from 'react-top-loading-bar';
import { Form } from 'react-bootstrap';
import {useEffect} from 'react';

const fromUrl = window.location.href.split('?')[1];

const decodedUrl = decoded(fromUrl || '');

const Login = () => {

  const [state,setState] = useState({role:"user"});
  
  const ref = useRef(null);

  const mutation = useMutation(Auth.login, {
      onSuccess: (s) => {
          const user = s.data;
          setLoggedInUser(user);
          if(decodedUrl.match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/)){
            const validAuth = JSON.stringify(user);
            const urlEncodedAuth = encoded(validAuth);
            window.location.href = decodedUrl +"?"+urlEncodedAuth;
          }else{
            setEntityCountry(user?.defaultCountry || "South Africa");
            setGlobalSettings(user.globalSettings[0]);  
            window.location.href = window.location.href.replace("/#/","/#/personal-details");
            window.location.reload();
          }
      },
      onSettled: () => {
      ref.current.complete();
      }
   });

   useEffect(()=>{
      document.title = window.location.origin.includes('agrostrong') ? 'Agrostrong' :'Tsigiro Usekelo'
   },[]);


  const handleLogin = (e) => {
      e.preventDefault(e)
      ref.current.continuousStart()
      mutation.mutate(state);
  };

  const handleForminput = (e) => {
    const {name,value} = e.target;
    setState({...state,[name]:value});
  };

  const logoReturn = ()=>{
      if(window.location.origin.includes('agrostrong')){
        return Agrostrong
      }else{
        return Logo
      }
  };

  return (
        <Form onSubmit={handleLogin}>
          <div className='container' style={{width:'100%'}} >
            <LoadingBar color='green' progress={5} ref={ref} />
            <div className='inner-container'>
              {
                    state.redirectHome && <Redirect to="personal-details"  /> 
              }
            <img  src={logoReturn()} className='logo-md'/>
            <h3>Welcome to {window.location.origin.includes('agrostrong') ? "Agrostrong" :"Tsigiro Usekelo"}</h3>
            <h4>Login to access your account</h4>
            {
              mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
            }
            <form style={{width:'100%'}}>
            <Input type="text" container={{margin:0}} label="Email or Username" edit name="email" value={state.email} onChange={handleForminput}/>
            <Input type="password" container={{margin:0}} label="Password" name="password" edit value={state.password} onChange={handleForminput}  />
            <button
            disabled={mutation.isLoading || !state.email || !state.password}
            style={{backgroundColor:mutation.isLoading || !state.email || !state.password ? '#ccc' : '#47878a'}}
            onClick={handleLogin}
            className='btn btn-primary'>Login</button>
            </form>
            <div>
                <p style={{margin:0}}>New to {window.location.origin.includes('agrostrong') ? "Agrostrong" :"Tsigiro Usekelo"} ? <span style={{textDecoration:'underline'}}><Link to="/registration">Register</Link></span></p>
            </div>
            <Link to="/forgot-password" style={{color:'#000',marginTop:20,textDecoration:'underline'}}><h4>Forgot password</h4></Link>
              { window.location.origin.includes('agrostrong') &&<>
                <img  src={Logo} className='logo-md' style={{height:20,width:20}}/>
              <p style={{marginTop:10}}>Powered by Tsigiro Usekelo</p>
                </>}
            </div>
          </div>
          {

          }
        </Form>
  )

};

export default Login;
