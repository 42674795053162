import React,{ useRef, useState } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Input from '../Inputs/Input';
import {decoded, encoded} from '../../API/RouterHelpers';
import {encryptInTransit} from '../../API/EncryptInTransit';
import {getDecodedUser, setLoggedInUser} from '../../API/Cookies';
import {useMutation} from 'react-query';
import Auth from '../../API/Auth';
import LoadingBar from 'react-top-loading-bar';

const ForgotPassFinisher = (props) => {

  const email = props.location.search.split('?')[1];

  const [state,setState] = useState({email:email});
  
  const ref = useRef(null);

  const mutation = useMutation(Auth.PasswordNew, {
      onSuccess: (s) => {
        setState({redirectHome:true});
      },
      onSettled: () => {
      ref.current.complete()
      }
   });

  const handleLogin = () => {
      ref.current.continuousStart()
      mutation.mutate(state);
  };
  const [passwordValidation, setPasswordValidation] = useState(false)

  const validatePassword = (password) => {
    var passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,200}$/;
    if(password.match(passw)) { 
      setPasswordValidation(true)
      console.log("strong")
    }
    else{ 
      setPasswordValidation(false);
    }
  }

  const handleForminput = (e) => {
    const {name,value} = e.target;
    setState({...state,[name]:value});
  };

  return (
    <div className='container'>
        <LoadingBar color='green' progress={5} ref={ref} />
        <div className='inner-container'>
            {
                state.redirectHome && <Redirect to="/"  /> 
            }
        <img  src={Logo} className='logo-md'/>
        <h3>Password Reset</h3>
        <h4>Enter New Password Below</h4>
        {
            mutation.isError && <p className='error-message'>{mutation?.error?.message}</p>
        }
        <Input   error={!passwordValidation && state.password || false?`Weak password!` : false}   type="password" label="Password" edit name="password" value={state.password} onChange={(e)=>{handleForminput(e); validatePassword(e.target.value) }} />
        <Input error={(state.password != state.repassword) && state.password || false?`Password do not match!` : false} type="password" label="Re-enter Password" name="repassword" edit value={state.repassword} onChange={handleForminput}  />
        <button
        disabled={ !passwordValidation || state.password != state.repassword || mutation.isLoading || !state.repassword || !state.password}
        style={{backgroundColor:mutation.isLoading || !state.password|| !state.repassword ? '#ccc' : '#47878a'}}
        onClick={handleLogin}
        className='btn btn-primary'>Submit</button>
        </div>
    </div>
  )

};

export default ForgotPassFinisher;
