import React, { useState } from 'react';
import FullLogo from '../../assets/full.logo.svg';
import Input from '../Inputs/Input';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation, useQuery } from 'react-query';
import Fundraiser from '../../API/Fundraiser';
import { decoded } from '../../API/RouterHelpers';

const InvestmentFailed = () => {

  return (
     <div className='account-verification'>
        <div className='left-verification'>
            <div className='text-verification'>
                    <h1>Standing with you, every step of your financial journey</h1>
             </div>
             <div className='ribbon-container'></div>
        </div>
        <div className='right-verification'>
                <img src={FullLogo}  />
            <h3>Failed to connect your investment</h3>
            <p style={{textAlign:'center',width:'70%'}} >
                Seems like we couldn't connect your investment to a valid payment or your investment has already been linked. Here are a few steps to take to sort this out
            </p>
            <ul>
                <li style={{fontSize:12}}>Verify if your investment has been linked to your account, on <a href='https://invest.tsigiro.com/' target='_blank'>https://invest.tsigiro.com/</a></li>
                <li style={{fontSize:12}}>Contact support with the email and amount you used to process the transaction on <a href='mailto:info@tsigiro.com' target='_blank'>info@tsigiro.com</a></li>
            </ul>
        </div>
        
    </div>
  )
}

export default InvestmentFailed;