import ApiClient from "./Client";
import { getDecodedUser, getLoggedInUser, isUserAuthenticated } from "./Cookies";

const version = `/api/v1`;

const params = {
    headers:{
        'x-access-token': isUserAuthenticated() ? getLoggedInUser()?.token: undefined,
    }
};

const user = getDecodedUser();

class AuthService {

    login = (data) => ApiClient().post(`${version}/users/login`,data);

    Registration = (data) => ApiClient().post(`${version}/users/register`, data);

    ForgotPassword = (data) => ApiClient().post(`${version}/users/${data.email}/forgot/password`,data);

    OTP = ( data ) => ApiClient().post(`${version}/users/register/email-verification`, data);

    ResendOTP = ( data ) => ApiClient().post(`${version}/users/register/resend-otp`, data);

    PasswordOTP = ( data ) => ApiClient().post(`${version}/users/password/otp/verification`, data);


    PasswordNew = ( data ) => ApiClient().post(`${version}/users/password/new`, data);

    Me = () => ApiClient().get(`${version}/users/me`, params);

    UpdatePersonalDetails = (data) => ApiClient().put(`${version}/users/${user.id}/update`,data,params);

    newBeneficiariesDetails = (data) => ApiClient().post(`${version}/beneficiaries/add`,data,params);

    updateBeneficiariesDetails = (data) => ApiClient().put(`${version}/beneficiaries/${data?._id}`,data,params);

    deleteBeneficiariesDetails = (id) => ApiClient().delete(`${version}/beneficiaries/${id}`,params);

    getBeneficiariesDetails = () => ApiClient().get(`${version}/beneficiaries`,params);

    UpdateAddressDetails = (data) => ApiClient().put(`${version}/users/address/${user.id}/update`,data, params);

    UpdateBankingDetails = (data) => ApiClient().put(`${version}/users/banking/${user.id}/update`,data,params);

    fetchBankingDetails = () => ApiClient().get(`${version}/users/banking/${user.id}/all`,params);

    fetchNotifications = () => ApiClient().get(`${version}/notifications/all/${user.id}`,params);

    updateNotification = (notificationId) => ApiClient().put(`${version}/notifications/update/${notificationId}`,{},params);

    newProfileGroup = (data) => ApiClient().post(`${version}/users/profilegroups/${user.id}/new`,data,params);

    editProfileGroup = (data) => ApiClient().put(`${version}/users/profilegroups/${data?._id}`,data,params);

    allProfileGroupsAndData = () => ApiClient().get(`${version}/users/profilegroups/${user.id}/all`,params);

    newProfileGroupMember = (data) => ApiClient().post(`${version}/users/profilegroups/member/${user.id}/new`,data,params);

    editProfileGroupMember = (data) => ApiClient().put(`${version}/users/profilegroups/member/${data._id}`,data,params);

    logout = () => ApiClient().post(`${version}/logout`);

    deleteProfileGroupMember = (id) => ApiClient().delete(`${version}/users/${id}`,params);
    deleteProfileGroup = (id) => ApiClient().delete(`${version}/users/profilegroups/${id}`,params);

}

export default new AuthService();