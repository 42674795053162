import React, { useState } from 'react';
import FullLogo from '../../assets/full.logo.svg';
import Input from '../Inputs/Input';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation, useQuery } from 'react-query';
import Fundraiser from '../../API/Fundraiser';
import { decoded } from '../../API/RouterHelpers';

const fromUrl = window.location.href.split('?')[1];

const decodedUrl = decoded(fromUrl || '');

const AccountVerification = () => {

    const [status, setStatus] = useState('login');
    const [state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Fundraiser.matchExisitingAccount, {
        onSuccess: (response) => {
            setStatus('success');
        },
        onError: (e) => {
            console.log(e)
        }
    });

    const { isLoading: loading, error: fetchError } = useQuery('fetch-investment', () => Fundraiser.getFundraiser(decodedUrl), {
        onSuccess: (response) => {
            console.log(decodedUrl, response);
            const data = response?.data?.fundraiser || {};
            setState({ ...state, ...data });
        },
        onError: (e) => {
            console.log(e)
        }
    });

    const { mutate: matchNewAccount, error: matchError, isLoading: matchLoading } = useMutation(Fundraiser.matchNewAccount, {
        onSuccess: (response) => {
            setStatus('success');
        },
        onError: (e) => {
            console.log(e)
        }
    });

    const [passwordValidation, setPasswordValidation] = useState(false);

    const [emailValidation, setEmailValidation] = useState(false);

    const validatePassword = (password) => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,200}$/;
        if (password.match(passw)) {
            setPasswordValidation(true)
        }
        else {
            setPasswordValidation(false);
        }
    };

    const validateEmail = (email) => {
        email = email.replace(/\s+/g, '');// remove all white spaces on the ID
        const valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(email)) ? true : false;
        setEmailValidation(valid);
        return valid;
    };



    return (
        <div className='account-verification'>
            <div className='left-verification'>
                <div className='text-verification'>
                    <h1>Standing with you, every step of your financial journey</h1>
                    {!loading && !fetchError && <p>Your investment to <strong>{state?.fundraiser?.entity?.entityName}</strong> (at VFX Listing) was successful</p>}
                </div>
                <div className='ribbon-container'></div>
            </div>
            {!loading ? <div className='right-verification'>
                {status !== 'register' && <img src={FullLogo} />}
                {status !== 'register' && <h3>Link your investment with an Account</h3>}
                {!fetchError && status !== 'register' && <p>Your investment referrence is <strong>{state?.transactionId}</strong></p>}
                {
                    status !== 'success' && status !== 'register' && error && <Alert variant='danger'  >{error?.message}</Alert>
                }
                {
                    fetchError && <Alert variant='danger' style={{ marginTop: 10 }} >Could not verify valid payment</Alert>
                }
                {status === 'login' && !fetchError && <div className='inner-container'>
                    <Input type="text"
                        onChange={(e) => { setState({ ...state, [e.target.name]: e.target.value, emailreg: null }) }}
                        container={{ margin: 0 }} label="Email or Username" edit="true" name="email" />

                    {isLoading ? <span class="loader"></span> : <button disabled={!state.email} className='btn btn-primary' onClick={() => mutate({ email: state.email, transactionId: state?.transactionId })} style={{ marginTop: 0 }}>
                        Proceed
                    </button>}
                </div>}
                {
                    status === 'register' && <div className='inner-container'>
                        {
                            matchError && <Alert variant='danger'  >{matchError?.message}</Alert>
                        }
                        <Input type="text"
                            onChange={(e) => { setState({ ...state, [e.target.name]: e.target.value, email: null }); validateEmail(e.target.value) }}
                            error={state?.email && !emailValidation ? `Invalid email` : false}
                            container={{ margin: 0 }} label="Email" edit="true" name="emailreg" />
                        <Input type="text" onChange={(e) => { setState({ ...state, [e.target.name]: e.target.value }) }} container={{ margin: 0 }} label="Username" edit="true" name="username" />
                        <Input error={!passwordValidation && state.password || false ? `Weak password!` : false} type="password" label="Password" name="password" edit onChange={(e) => { validatePassword(e.target.value); setState({ ...state, [e.target.name]: e.target.value }) }} />
                        {
                            !passwordValidation &&
                            <span style={{ fontSize: 12, color: "red" }}>The passord must include at least 1 Capital Letter, 1 small letter, 1 number, 1 special character e.g {'('} ? . # $ {')'}</span>
                        }
                        <Input error={(state.password != state.confirmpass) && state.password || false ? `Password do not match!` : false} type="password" label="Confirm Password" name="confirmpass" edit onChange={(e) => { setState({ ...state, [e.target.name]: e.target.value }) }} />
                        {matchLoading ? <span class="loader"></span> : <button className='btn btn-primary'
                            disabled={matchLoading || !state.emailreg || !state.username || !state.password || !state.confirmpass || (state.password != state.confirmpass)}
                            onClick={() => matchNewAccount({ email: state.emailreg, transactionId: state?.transactionId, username: state.username, password: state.password })}
                            style={{ zIndex: 100000 }}>
                            Proceed
                        </button>}
                    </div>
                }
                {status === 'login' ? !fetchError && <p style={{ margin: 0, cursor: 'pointer' }}
                    onClick={() => setStatus('register')}
                >New to Tsigiro Usekelo? <span style={{ textDecoration: 'underline' }}>Register</span></p>
                    : !fetchError && status === 'register' && <p style={{ margin: 0, cursor: 'pointer' }}
                        onClick={() => setStatus('login')}
                    >Already have an account? <span style={{ textDecoration: 'underline' }}>Login</span></p>
                }
                {
                    status === 'success' &&
                    <h4>We've sent an email to {state.email || state.emailreg}, click on the link provided to complete your investment</h4>
                }
            </div> :
                <div className='right-verification'>
                    <img src={FullLogo} />
                    <span class="loader"></span>
                </div>
            }
        </div>
    )
}

export default AccountVerification;