import React, {useCallback,useState} from 'react'
import {useDropzone} from 'react-dropzone'

function FileUploader(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
   const [selectedFiles, setSelectedFiles] = useState([]);

  const handleAcceptedFiles = (files) => {
        var allFiles = files;

        if (props.showPreview) {
            (files || []).map((file) =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: formatBytes(file.size,2),
                })
            );
            allFiles = [...selectedFiles];
            allFiles.push(...files);
            setSelectedFiles(allFiles);
        }

        if (props.onFileUpload) props.onFileUpload(allFiles);
    };

      const formatBytes = (bytes ,decimals) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const removeFile = (e ,fileIndex) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(fileIndex, 1);
        setSelectedFiles(newFiles);
        if (props.onFileUpload) props.onFileUpload(newFiles);
    };

    

  return (
    <div {...getRootProps()} className="file-uploader">
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <div style={{width:'100%'}}>
            <h3 style={{textAlign:'center'}}>{props.title}</h3>
          <p style={{textAlign:'center'}}>Drag and drop some files here, or click to select files</p>
          </div>
      }
    </div>
  )
}

export default FileUploader;