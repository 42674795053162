import React, {useEffect} from 'react'
import {Col, Row, Spinner} from 'react-bootstrap'

const CompleteDO = (props) => {
  
    useEffect(()=>{
        const starter = setTimeout(()=>{
            window.location.reload();
        },3000)

        return ()=> clearTimeout(starter);
    },[]);

  return (
    <Row>
        <Col lg="3">
        </Col>
        <Col lg="6">
           <h2> All set up !</h2>
           <p>Your debit order has been successfully setup. You can disable this debit order / subscription under billing and banking of your id.tsigiro.com account.
           </p>
           <p style={{textAlign:'center'}}><Spinner></Spinner></p>
           <p>Once your account has been credited, you'll be notified on email.
           </p>
        </Col>
        <Col lg="3">
        </Col>
    </Row>
  )
}

export default CompleteDO;